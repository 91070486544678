<script setup lang="ts">
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { useAuthStore } from '@/stores/AuthStore.ts';
import { useNotificationStore } from '@/stores/NotificationStore.ts';
import { usePaginationStore } from '@/stores/PaginationStore.ts';
import { useProjectsStore } from '@/stores/ProjectsStore.ts';
import { useStylesStore } from '@/stores/StylesStore.ts';

import { Rights } from '@/client';
import { hasProjectRights, isSuperAdmin } from '@/composables/Auth.ts';

import {
  notificationsOutlined,
  notificationsUnreadOutlined,
} from '@/assets/icons/materialSymbols/MaterialSymbols.ts';

import { headerIds } from '@/utils/DataTestIds.ts';

const route = useRoute();
const router = useRouter();

const authStore = useAuthStore();
const projectsStore = useProjectsStore();
const paginationStore = usePaginationStore();
const notificationStore = useNotificationStore();
const stylesStore = useStylesStore();
const showUnread = ref(false);

function setActiveTab(tab: string) {
  if (!projectsStore.project) return;

  paginationStore.setFrameworkQuery(null);
  paginationStore.setStatusFilter(null);
  paginationStore.setTagFilter(null);
  paginationStore.setNameFilter('');
  paginationStore.setAssignedToMe(false);

  projectsStore.setActiveTab(tab);
}

function toNotifications() {
  router.push({
    name: 'notifications',
  });
}

watch(
  () => notificationStore.newNotificationCount,
  (newV) => {
    if (newV > 0) {
      showUnread.value = true;
    } else {
      showUnread.value = false;
    }
  },
  {
    immediate: true,
  },
);

watch(
  () => route.name,
  () => {
    paginationStore.setFrameworkQuery(null);
    paginationStore.setStatusFilter(null);
    paginationStore.setTagFilter(null);
    paginationStore.setNameFilter('');
    paginationStore.setAssignedToMe(false);
  },
);
</script>

<template>
  <q-header
    bordered
    class="text-white q-py-xs"
    :style="{ left: stylesStore.drawerOpened ? '260px' : '77px' }"
  >
    <q-toolbar>
      <div v-if="projectsStore.project" class="menu">
        <q-btn
          flat
          class="path"
          :to="`/projects/project-detail/${projectsStore.project.id}`"
          :class="route.name === 'project-dashboard' ? 'active' : ''"
          @click="setActiveTab('dashboard')"
        >
          Dashboard
        </q-btn>
        <!-- <q-btn
          flat
          class="path"
          :to="`/projects/project-detail/${projectsStore.project.id}/frameworks`"
          :class="route.name === 'project-frameworks' ? 'active' : ''"
          @click="setActiveTab('frameworks')"
        >
          Frameworks
        </q-btn> -->
        <q-btn
          flat
          class="path"
          :to="{
            name: 'project-requirements',
            params: route.params,
          }"
          :class="route.name === 'project-requirements' ? 'active' : ''"
          @click="setActiveTab('requirements')"
        >
          Requirements
        </q-btn>
        <q-btn
          flat
          class="path"
          :to="{
            name: 'project-controls',
            params: route.params,
            query: route.query,
          }"
          :class="route.name === 'project-controls' ? 'active' : ''"
          @click="setActiveTab('controls')"
        >
          Controls
        </q-btn>
        <q-btn
          flat
          class="path"
          :to="{
            name: 'project-risks',
            params: route.params,
            query: route.query,
          }"
          :class="route.name === 'project-risks' || route.name === 'risk-detail' ? 'active' : ''"
          @click="setActiveTab('risks')"
        >
          Risks
        </q-btn>
        <q-btn
          flat
          class="path"
          :to="{
            name: 'project-evidence',
            params: route.params,
            query: route.query,
          }"
          :class="route.name === 'project-evidence' ? 'active' : ''"
          @click="setActiveTab('evidence')"
        >
          Evidence
        </q-btn>
        <q-btn
          flat
          class="path"
          :to="{
            name: 'project-assets',
            params: route.params,
            query: route.query,
          }"
          :class="route.name === 'project-assets' || route.name === 'asset-detail' ? 'active' : ''"
          @click="setActiveTab('assets')"
        >
          Assets
        </q-btn>
        <q-btn
          flat
          class="path"
          :to="{
            name: 'project-testing',
            params: route.params,
            query: route.query,
          }"
          :class="route.name === 'project-testing' || route.name === 'test-detail' ? 'active' : ''"
          @click="setActiveTab('testing')"
        >
          Testing
          <q-badge class="preview-badge q-ml-xs">PREVIEW</q-badge>
        </q-btn>
        <q-btn
          v-if="
            isSuperAdmin() ||
            hasProjectRights(projectsStore.project.id, Rights.Update) ||
            hasProjectRights(projectsStore.project.id, Rights.Delete) ||
            hasProjectRights(projectsStore.project.id, Rights.UserRolesassign)
          "
          flat
          class="path"
          :to="{
            name: 'project-settings',
            params: route.params,
            query: route.query,
          }"
          :class="route.name === 'project-settings' ? 'active' : ''"
          @click="setActiveTab('settings')"
        >
          Settings
        </q-btn>
      </div>
      <q-space />
      <div class="notifications__container row items-center col-2 justify-end">
        <div class="org-name__container row items-center">
          <span>{{
            authStore.user && 'organization' in authStore.user
              ? authStore.user.organization.name
              : null
          }}</span>
        </div>
        <q-icon
          v-if="!showUnread"
          :name="notificationsOutlined"
          :data-test-id="headerIds.notificationsIcon"
          size="24px"
          color="blue-grey-7"
          @click="toNotifications"
        />
        <q-icon
          v-if="showUnread"
          :name="notificationsUnreadOutlined"
          :data-test-id="headerIds.notificationsUnreadIcon"
          color="red-5"
          size="24px"
          @click="toNotifications"
        />
      </div>
    </q-toolbar>
  </q-header>
</template>

<style lang="scss" scoped>
.q-header {
  background: $white;
  border: none;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
  :deep(svg) {
    cursor: pointer;
  }

  .q-toolbar {
    height: 62px;
    padding: 10px 36px;
    @media screen and (max-width: 1250px) {
      height: auto;
    }
    .menu {
      a {
        @include paragraph-02(400, $secondary-500);
        text-transform: none;
        padding: 4px;
        margin: 0 16px;
      }
      .active {
        @include paragraph-02(700, $secondary-600);
        position: relative;
      }
      .active::after {
        content: '';
        width: 100%;
        height: 3px;
        background: $secondary-600;
        position: absolute;
        bottom: -15px;
        right: 0;
      }
    }
    .header__title {
      @include paragraph-01(400, $secondary-800);
      opacity: 0.4;
    }
    .user {
      span {
        @include paragraph-01(400, $secondary-800);
      }
      .q-avatar {
        background: $secondary-100;
        span {
          @include paragraph-02(400, $secondary-800);
        }
      }
    }
    button {
      width: 48px;
      height: 48px;
      background: #fafafa;
      line-height: normal;
    }
    button:hover {
      background: $primary-50;
    }
    .notifications__container {
      height: 100%;
      position: relative;

      .org-name__container {
        height: 100%;
        padding: 0 15px;
        margin-right: 10px;
        border-right: 1px solid $secondary-100;

        span {
          font-size: 14px;
          color: $secondary-500;
        }
      }
      .dot-icon {
        position: absolute;
        top: -4px;
        right: 0;
      }
    }
  }
}
</style>
