<script setup lang="ts">
import { ref, watch } from 'vue';

const props = defineProps({
  showDialog: {
    type: Boolean,
    default: false,
  },
  maxHeight: {
    type: String,
    default: '',
  },
  maxWidth: {
    type: String,
    default: '',
  },
  minHeight: {
    type: String,
    default: '',
  },
  minWidth: {
    type: String,
    default: '',
  },
  background: {
    type: String,
    default: '#f7f8f9',
  },
  persistent: {
    type: Boolean,
    default: false,
  },
  sticky: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['hide']);

const dialog = ref(false);

watch(
  () => props.showDialog,
  (value) => {
    dialog.value = value;
  },
  {
    immediate: true,
  },
);
</script>

<template>
  <q-dialog v-model="dialog" no-refocus :persistent="persistent" @hide="emit('hide')">
    <q-card
      :style="{
        'max-height': maxHeight,
        'max-width': maxWidth,
        'min-height': minHeight,
        'min-width': minWidth,
        background: background,
      }"
    >
      <q-card-section :class="sticky ? 'sticky-section' : ''">
        <slot />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<style scoped lang="scss">
.q-card {
  min-width: 88vw;
  min-height: 92vh;
  max-width: 88vw !important;
  max-height: 92vh !important;
  background: $background;
  :deep(.q-card__section) {
    padding: 30px 24px;
  }
  :deep(.sticky-section) {
    padding: 0 24px;
    .header__row {
      position: sticky;
      padding: 24px 0 10px;
      top: 0;
      background-color: rgb(247, 248, 249);
      z-index: 999;
    }

    .footer__row {
      position: sticky;
      padding: 10px 0 24px;
      bottom: 0;
      background-color: rgb(247, 248, 249);
      z-index: 999;
    }
  }
}
</style>
