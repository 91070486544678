import { ref } from 'vue';

// user_management_enabled_for_entire_organization

// Flag is false, you are SuperAdmin → You can see the page.
// Flag is false, you are not SuperAdmin, and you lack specific rights → You cannot see the page.
// Flag is true, you are SuperAdmin or have specific rights → You can see the page.
// Flag is true, you are not SuperAdmin and lack specific rights → You cannot see the page.

import { OrganizationRights } from '../client/index.ts';
import { hasOrganizationRights, isSuperAdmin } from './Auth.ts';

import { useConfigStore } from '@/stores/ConfigStore.ts';

export const haveUserAccess = () => {
  const configStore = useConfigStore();

  const isSuperAdminUser = isSuperAdmin();
  const flagEnabled = configStore.usersEnabled;
  const hasProjectFullAccess =
    configStore.configData && hasOrganizationRights(OrganizationRights.ProjectsfullAccess);
  const hasOrganizationUsersReadRight =
    configStore.configData && hasOrganizationRights(OrganizationRights.Usersread);

  if (isSuperAdminUser || hasProjectFullAccess) return true;

  if (!flagEnabled) return false;

  return hasOrganizationUsersReadRight;
};

export const showContactSalesDialog = ref<boolean>(false);

export const showContactSalesModal = () => {
  showContactSalesDialog.value = true;
};

export const hideContactSalesModal = () => {
  showContactSalesDialog.value = false;
};
