import { createRouter, createWebHistory } from 'vue-router';

import { useAuthStore } from '@/stores/AuthStore.ts';
import { useConfigStore } from '@/stores/ConfigStore.ts';

import { hasOrganizationRights, isSuperAdmin, useAuth } from '@/composables/Auth.ts';
import { haveUserAccess } from '@/composables/Flags.ts';
import { isProd } from '@/composables/Env.ts';
import { deviceSupported } from '@/composables/Device.ts';

import AuthLayout from '@/layouts/AuthLayout.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import ErrorLayout from '@/layouts/ErrorLayout.vue';
import OrganizationLayout from '@/layouts/OrganizationLayout.vue';
import ProjectLayout from '@/layouts/ProjectLayout.vue';
import { OrganizationRights } from '@/client';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      meta: { layout: DefaultLayout },
      component: () => import('@/pages/DashboardPage.vue'),
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      meta: { layout: DefaultLayout },
      component: () => import('@/pages/DashboardPage.vue'),
    },
    {
      path: '/projects',
      name: 'projects',
      meta: { layout: DefaultLayout },
      component: () => import('@/pages/ProjectsPage.vue'),
    },
    {
      path: '/projects/project-detail/:id',
      name: 'project-detail',
      meta: { layout: ProjectLayout },
      component: () => import('@/pages/ProjectDetailPage.vue'),
      children: [
        {
          path: '',
          name: 'project-dashboard',
          component: () => import('@/components/ProjectDetail/Dashboard.vue'),
        },
        {
          path: 'requirements',
          name: 'project-requirements',
          component: () => import('@/components/ProjectDetail/Requirements.vue'),
        },
        {
          path: 'controls',
          name: 'project-controls',
          component: () => import('@/components/ProjectDetail/Controls.vue'),
        },
        {
          path: 'settings',
          name: 'project-settings',
          component: () => import('@/components/ProjectDetail/Settings.vue'),
        },
        {
          path: 'risks',
          name: 'project-risks',
          component: () => import('@/components/ProjectDetail/Risks.vue'),
        },
        {
          path: 'risk-detail/:riskId',
          name: 'risk-detail',
          component: () => import('@/pages/RiskDetailPage.vue'),
        },
        {
          path: 'evidence',
          name: 'project-evidence',
          component: () => import('@/components/ProjectDetail/Evidence.vue'),
        },

        {
          path: 'assets',
          name: 'project-assets',
          component: () => import('@/components/ProjectDetail/Assets.vue'),
        },
        {
          path: 'asset-detail/:assetId',
          name: 'asset-detail',
          component: () => import('@/pages/AssetDetailPage.vue'),
        },
        {
          path: 'testing',
          name: 'project-testing',
          component: () => import('@/components/ProjectDetail/Testing.vue'),
        },
        {
          path: 'test-detail/:testId',
          name: 'test-detail',
          component: () => import('@/pages/TestingDetailPage.vue'),
        },
      ],
      beforeEnter: (to, from) => {
        to.params.activeTab = from.name as string;
      },
    },
    {
      path: '/projects/new-project',
      name: 'new-project',
      meta: { layout: DefaultLayout },
      component: () => import('@/pages/NewProjectPage.vue'),
    },
    {
      path: '/notifications',
      name: 'notifications',
      meta: { layout: DefaultLayout },
      component: () => import('@/pages/NotificationsPage.vue'),
    },
    {
      path: '/organization',
      name: 'organization',
      meta: { layout: OrganizationLayout },
      component: () => import('@/pages/OrganizationPage.vue'),
      beforeEnter: async () => {
        const authStore = useAuthStore();

        if (!authStore.user) {
          await authStore.getMeAndOrgConfig();
        }
      },
      children: [
        {
          path: '',
          name: 'organization-default',
          component: () => import('@/components/Organization/Users.vue'),
          beforeEnter: async () => {
            if (haveUserAccess()) {
              return { name: 'organization-users' };
            }

            return { name: 'organization-templates' };
          },
        },
        {
          path: 'users',
          name: 'organization-users',
          component: () => import('@/components/Organization/Users.vue'),
          beforeEnter: async () => {
            if (haveUserAccess()) {
              return true;
            }

            return { name: 'organization-templates' };
          },
        },
        {
          path: 'templates',
          name: 'organization-templates',
          component: () => import('@/components/Organization/Templates.vue'),
        },
        {
          path: 'template-detail/:templateCode',
          name: 'template-detail',
          component: () => import('@/pages/FrameworkDetailPage.vue'),
        },
        {
          path: 'customization',
          name: 'organization-customization',
          component: () => import('@/components/Organization/Taxonomy.vue'),
        },
        {
          path: 'settings',
          name: 'organization-settings',
          component: () => import('@/components/Organization/Settings.vue'),
          beforeEnter: async () => {
            const configStore = useConfigStore();
            if (
              isSuperAdmin() ||
              (configStore.configData && hasOrganizationRights(OrganizationRights.Configread))
            ) {
              return true;
            }

            return { name: 'organization-templates' };
          },
        },
      ],
    },
    {
      path: '/vendors',
      name: 'vendors',
      meta: { layout: DefaultLayout },
      component: () => import('@/pages/VendorsPage.vue'),
      beforeEnter: async () => {
        const authStore = useAuthStore();
        const configStore = useConfigStore();

        if (!authStore.user) {
          await authStore.getMeAndOrgConfig();
        }

        if (!configStore.configData || !authStore.user || !configStore.vendorsEnabled) {
          return { name: 'home' };
        }
      },
      children: [
        {
          path: '',
          name: 'vendors-overview',
          component: () => import('@/components/Vendors/VendorsOverview.vue'),
        },
        {
          path: 'vendor-detail/:vendorId',
          name: 'vendor-detail',
          component: () => import('@/pages/VendorDetailPage.vue'),
        },
      ],
    },
    {
      path: '/metrics',
      name: 'metrics',
      meta: { layout: DefaultLayout },
      component: () => import('@/pages/MetricsPage.vue'),
      beforeEnter: async () => {
        const authStore = useAuthStore();

        if (!authStore.user) {
          await authStore.getMeAndOrgConfig();
        }

        if (isSuperAdmin() || hasOrganizationRights(OrganizationRights.Internalmetricsread)) {
          return true;
        }

        return { name: 'home' };
      },
    },
    {
      path: '/oauth2-redirect',
      name: 'oauth2-redirect',
      meta: { layout: AuthLayout },
      component: () => import('@/pages/OAuth2RedirectPage.vue'),
    },
    {
      path: '/oauth2-signup-redirect',
      name: 'oauth2-signup-redirect',
      meta: { layout: AuthLayout },
      component: () => import('@/pages/OAuth2SignupRedirectPage.vue'),
    },
    {
      path: '/login',
      name: 'login-page',
      meta: {
        layout: AuthLayout,
      },
      component: () => import('@/pages/LoginPage.vue'),
    },
    {
      path: '/mobile-not-supported',
      name: 'mobile-not-supported',
      meta: {
        layout: AuthLayout,
      },
      component: () => import('@/pages/MobileNotSupportedPage.vue'),
      beforeEnter: () => {
        if (deviceSupported()) {
          return { name: 'login-page' };
        }
      },
    },
    {
      path: '/tokens',
      name: 'tokens',
      meta: {
        layout: DefaultLayout,
      },
      component: () => import('@/pages/TokenPage.vue'),
    },
    {
      path: '/service_down',
      name: 'service_down',
      component: () => import('@/pages/ErrorPage_v1.vue'),
    },
    {
      path: '/not_found',
      name: 'not-found',
      meta: { layout: ErrorLayout },
      component: () => import('@/pages/ErrorPage_v2.vue'),
    },
    {
      path: '/problem',
      name: 'problem',
      component: () => import('@/pages/ProblemPage.vue'),
    },
    {
      path: '/no_organization',
      name: 'no-organization',
      component: () => import('@/pages/NoOrganizationPage.vue'),
    },
    {
      path: '/:catchAll(.*)',
      name: 'not-found',
      meta: { layout: DefaultLayout },
      component: () => import('@/pages/ErrorPage_v2.vue'),
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  // Disable all pages for screen resolutions below 800px in production
  if (isProd && !deviceSupported() && to.name !== 'mobile-not-supported') {
    next({ name: 'mobile-not-supported' });
    return;
  }

  const store = useAuthStore();
  const access_token = localStorage.getItem('access_token');
  const refresh_token = localStorage.getItem('refresh_token');
  const user = localStorage.getItem('user');
  const parsedUser = user ? JSON.parse(user) : null;
  const publicPages = [
    'login-page',
    'oauth2-redirect',
    'oauth2-signup-redirect',
    'mobile-not-supported',
    'no-organization',
    'problem',
  ];

  const toName = to.name ? to.name.toString() : null;
  const isPublicPage = toName ? publicPages.includes(toName) : false;
  document.title = 'Modulos';

  const { logout } = useAuth();

  if (
    (!access_token && !isPublicPage && !refresh_token) ||
    (parsedUser && !parsedUser.terms_accepted && !isPublicPage)
  ) {
    if (parsedUser && !parsedUser.terms_accepted && !isPublicPage) {
      logout();
      return;
    }

    await store.getAccessTokenFromRefreshToken();

    if (!access_token && !isPublicPage && !refresh_token) {
      logout();
      return;
    }

    next();
  } else if (user && !('organization' in JSON.parse(user))) {
    if (toName !== 'no-organization') {
      next({ name: 'no-organization' });
      return;
    }
  }

  next();
});

export default router;
