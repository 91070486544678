/* tslint:disable */
/* eslint-disable */
/**
 * Modulos Platform API
 * API for the Modulos Platform.
 *
 * The version of the OpenAPI document: 1.0.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const OrganizationRights = {
    Update: 'organizations:update',
    Usersread: 'organizations:users:read',
    Usersupdate: 'organizations:users:update',
    Usersstatusupdate: 'organizations:users:status:update',
    Usersinvite: 'organizations:users:invite',
    UserRolesassign: 'organizations:user_roles:assign',
    UserRolesdelete: 'organizations:user_roles:delete',
    UserRolesupdate: 'organizations:user_roles:update',
    ProjectsfullAccess: 'organizations:projects:full_access',
    Projectsread: 'organizations:projects:read',
    Configread: 'organizations:config:read',
    Configupdate: 'organizations:config:update',
    Configpatch: 'organizations:config:patch',
    Frameworksread: 'organizations:frameworks:read',
    Quotaread: 'organizations:quota:read',
    Vendorsread: 'organizations:vendors:read',
    Vendorscreate: 'organizations:vendors:create',
    Vendorsupdate: 'organizations:vendors:update',
    Vendorsdelete: 'organizations:vendors:delete',
    Vendorsdocumentsupload: 'organizations:vendors:documents:upload',
    Vendorsdocumentsread: 'organizations:vendors:documents:read',
    Vendorsdocumentsdelete: 'organizations:vendors:documents:delete',
    VendorsdocumentsreadFileUrl: 'organizations:vendors:documents:read_file_url',
    Vendorsdocumentsdownload: 'organizations:vendors:documents:download',
    Templatesupload: 'organizations:templates:upload',
    Templatesread: 'organizations:templates:read',
    Templatesdelete: 'organizations:templates:delete',
    Filesread: 'organizations:files:read',
    Tagsread: 'organizations:tags:read',
    Internalmetricsread: 'organizations:internal:metrics:read'
} as const;

export type OrganizationRights = typeof OrganizationRights[keyof typeof OrganizationRights];



