/* tslint:disable */
/* eslint-disable */
/**
 * Modulos Platform API
 * API for the Modulos Platform.
 *
 * The version of the OpenAPI document: 1.0.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { ErrorResponse } from '../model';
// @ts-ignore
import type { HTTPValidationError } from '../model';
// @ts-ignore
import type { ResponseGetUserApiUsersUserIdGet } from '../model';
// @ts-ignore
import type { ResponseGetUsersMeApiUsersMeGet } from '../model';
// @ts-ignore
import type { Roles } from '../model';
// @ts-ignore
import type { UpdateUser } from '../model';
/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel Pending User Invites
         * @param {string} userId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelPendingUserInvitesApiUsersUserIdCancelInvitesPost: async (userId: string, organizationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('cancelPendingUserInvitesApiUsersUserIdCancelInvitesPost', 'userId', userId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('cancelPendingUserInvitesApiUsersUserIdCancelInvitesPost', 'organizationId', organizationId)
            const localVarPath = `/api/users/{user_id}/cancel-invites`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete User Roles
         * @param {string} userId 
         * @param {Roles} role 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserRolesApiUsersUserIdRolesDelete: async (userId: string, role: Roles, projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUserRolesApiUsersUserIdRolesDelete', 'userId', userId)
            // verify required parameter 'role' is not null or undefined
            assertParamExists('deleteUserRolesApiUsersUserIdRolesDelete', 'role', role)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('deleteUserRolesApiUsersUserIdRolesDelete', 'projectId', projectId)
            const localVarPath = `/api/users/{user_id}/roles`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the user with the given id.  Args:     user_id (uuid.UUID): The user id.  Returns:     schemas.User: The user with the given id.
         * @summary Get User
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserApiUsersUserIdGet: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserApiUsersUserIdGet', 'userId', userId)
            const localVarPath = `/api/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the current user.  Returns:     schemas.User: The current user.
         * @summary Get Users Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersMeApiUsersMeGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch User Roles
         * @param {string} userId 
         * @param {string} projectId 
         * @param {Array<Roles>} roles 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUserRolesApiUsersUserIdRolesPatch: async (userId: string, projectId: string, roles: Array<Roles>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('patchUserRolesApiUsersUserIdRolesPatch', 'userId', userId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('patchUserRolesApiUsersUserIdRolesPatch', 'projectId', projectId)
            // verify required parameter 'roles' is not null or undefined
            assertParamExists('patchUserRolesApiUsersUserIdRolesPatch', 'roles', roles)
            const localVarPath = `/api/users/{user_id}/roles`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roles, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Allows users to update their information.  Args:     user (schemas.UpdateUser): The new user information.     current_user (schemas.User, optional): The current user. Defaults         to Depends(dependencies.get_current_active_user).     db (Session, optional): Database session. Defaults to Depends(get_db).
         * @summary Patch Users Me
         * @param {UpdateUser} updateUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUsersMeApiUsersMePatch: async (updateUser: UpdateUser, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateUser' is not null or undefined
            assertParamExists('patchUsersMeApiUsersMePatch', 'updateUser', updateUser)
            const localVarPath = `/api/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the last access of the user.  Args:     current_user (schemas.User, optional): The current user. Defaults         to Depends(dependencies.get_current_active_user).     db (Session, optional): Database session. Defaults to Depends(get_db).
         * @summary Patch Users Me Last Access
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUsersMeLastAccessApiUsersMeLastAccessPatch: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/me/last_access`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post User Roles
         * @param {string} userId 
         * @param {Roles} role 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserRolesApiUsersUserIdRolesPost: async (userId: string, role: Roles, projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('postUserRolesApiUsersUserIdRolesPost', 'userId', userId)
            // verify required parameter 'role' is not null or undefined
            assertParamExists('postUserRolesApiUsersUserIdRolesPost', 'role', role)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('postUserRolesApiUsersUserIdRolesPost', 'projectId', projectId)
            const localVarPath = `/api/users/{user_id}/roles`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resend User Invite
         * @param {string} userId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendUserInviteApiUsersUserIdResendInvitePost: async (userId: string, organizationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('resendUserInviteApiUsersUserIdResendInvitePost', 'userId', userId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('resendUserInviteApiUsersUserIdResendInvitePost', 'organizationId', organizationId)
            const localVarPath = `/api/users/{user_id}/resend-invite`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel Pending User Invites
         * @param {string} userId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelPendingUserInvitesApiUsersUserIdCancelInvitesPost(userId: string, organizationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelPendingUserInvitesApiUsersUserIdCancelInvitesPost(userId, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.cancelPendingUserInvitesApiUsersUserIdCancelInvitesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete User Roles
         * @param {string} userId 
         * @param {Roles} role 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserRolesApiUsersUserIdRolesDelete(userId: string, role: Roles, projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserRolesApiUsersUserIdRolesDelete(userId, role, projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.deleteUserRolesApiUsersUserIdRolesDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the user with the given id.  Args:     user_id (uuid.UUID): The user id.  Returns:     schemas.User: The user with the given id.
         * @summary Get User
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserApiUsersUserIdGet(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseGetUserApiUsersUserIdGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserApiUsersUserIdGet(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getUserApiUsersUserIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the current user.  Returns:     schemas.User: The current user.
         * @summary Get Users Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersMeApiUsersMeGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseGetUsersMeApiUsersMeGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersMeApiUsersMeGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getUsersMeApiUsersMeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Patch User Roles
         * @param {string} userId 
         * @param {string} projectId 
         * @param {Array<Roles>} roles 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchUserRolesApiUsersUserIdRolesPatch(userId: string, projectId: string, roles: Array<Roles>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchUserRolesApiUsersUserIdRolesPatch(userId, projectId, roles, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.patchUserRolesApiUsersUserIdRolesPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Allows users to update their information.  Args:     user (schemas.UpdateUser): The new user information.     current_user (schemas.User, optional): The current user. Defaults         to Depends(dependencies.get_current_active_user).     db (Session, optional): Database session. Defaults to Depends(get_db).
         * @summary Patch Users Me
         * @param {UpdateUser} updateUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchUsersMeApiUsersMePatch(updateUser: UpdateUser, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchUsersMeApiUsersMePatch(updateUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.patchUsersMeApiUsersMePatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the last access of the user.  Args:     current_user (schemas.User, optional): The current user. Defaults         to Depends(dependencies.get_current_active_user).     db (Session, optional): Database session. Defaults to Depends(get_db).
         * @summary Patch Users Me Last Access
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchUsersMeLastAccessApiUsersMeLastAccessPatch(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchUsersMeLastAccessApiUsersMeLastAccessPatch(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.patchUsersMeLastAccessApiUsersMeLastAccessPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Post User Roles
         * @param {string} userId 
         * @param {Roles} role 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUserRolesApiUsersUserIdRolesPost(userId: string, role: Roles, projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUserRolesApiUsersUserIdRolesPost(userId, role, projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.postUserRolesApiUsersUserIdRolesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Resend User Invite
         * @param {string} userId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendUserInviteApiUsersUserIdResendInvitePost(userId: string, organizationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendUserInviteApiUsersUserIdResendInvitePost(userId, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.resendUserInviteApiUsersUserIdResendInvitePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel Pending User Invites
         * @param {UsersApiCancelPendingUserInvitesApiUsersUserIdCancelInvitesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelPendingUserInvitesApiUsersUserIdCancelInvitesPost(requestParameters: UsersApiCancelPendingUserInvitesApiUsersUserIdCancelInvitesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.cancelPendingUserInvitesApiUsersUserIdCancelInvitesPost(requestParameters.userId, requestParameters.organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete User Roles
         * @param {UsersApiDeleteUserRolesApiUsersUserIdRolesDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserRolesApiUsersUserIdRolesDelete(requestParameters: UsersApiDeleteUserRolesApiUsersUserIdRolesDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteUserRolesApiUsersUserIdRolesDelete(requestParameters.userId, requestParameters.role, requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the user with the given id.  Args:     user_id (uuid.UUID): The user id.  Returns:     schemas.User: The user with the given id.
         * @summary Get User
         * @param {UsersApiGetUserApiUsersUserIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserApiUsersUserIdGet(requestParameters: UsersApiGetUserApiUsersUserIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResponseGetUserApiUsersUserIdGet> {
            return localVarFp.getUserApiUsersUserIdGet(requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the current user.  Returns:     schemas.User: The current user.
         * @summary Get Users Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersMeApiUsersMeGet(options?: RawAxiosRequestConfig): AxiosPromise<ResponseGetUsersMeApiUsersMeGet> {
            return localVarFp.getUsersMeApiUsersMeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch User Roles
         * @param {UsersApiPatchUserRolesApiUsersUserIdRolesPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUserRolesApiUsersUserIdRolesPatch(requestParameters: UsersApiPatchUserRolesApiUsersUserIdRolesPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.patchUserRolesApiUsersUserIdRolesPatch(requestParameters.userId, requestParameters.projectId, requestParameters.roles, options).then((request) => request(axios, basePath));
        },
        /**
         * Allows users to update their information.  Args:     user (schemas.UpdateUser): The new user information.     current_user (schemas.User, optional): The current user. Defaults         to Depends(dependencies.get_current_active_user).     db (Session, optional): Database session. Defaults to Depends(get_db).
         * @summary Patch Users Me
         * @param {UsersApiPatchUsersMeApiUsersMePatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUsersMeApiUsersMePatch(requestParameters: UsersApiPatchUsersMeApiUsersMePatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.patchUsersMeApiUsersMePatch(requestParameters.updateUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the last access of the user.  Args:     current_user (schemas.User, optional): The current user. Defaults         to Depends(dependencies.get_current_active_user).     db (Session, optional): Database session. Defaults to Depends(get_db).
         * @summary Patch Users Me Last Access
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUsersMeLastAccessApiUsersMeLastAccessPatch(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.patchUsersMeLastAccessApiUsersMeLastAccessPatch(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post User Roles
         * @param {UsersApiPostUserRolesApiUsersUserIdRolesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserRolesApiUsersUserIdRolesPost(requestParameters: UsersApiPostUserRolesApiUsersUserIdRolesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.postUserRolesApiUsersUserIdRolesPost(requestParameters.userId, requestParameters.role, requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resend User Invite
         * @param {UsersApiResendUserInviteApiUsersUserIdResendInvitePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendUserInviteApiUsersUserIdResendInvitePost(requestParameters: UsersApiResendUserInviteApiUsersUserIdResendInvitePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.resendUserInviteApiUsersUserIdResendInvitePost(requestParameters.userId, requestParameters.organizationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for cancelPendingUserInvitesApiUsersUserIdCancelInvitesPost operation in UsersApi.
 * @export
 * @interface UsersApiCancelPendingUserInvitesApiUsersUserIdCancelInvitesPostRequest
 */
export interface UsersApiCancelPendingUserInvitesApiUsersUserIdCancelInvitesPostRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiCancelPendingUserInvitesApiUsersUserIdCancelInvitesPost
     */
    readonly userId: string

    /**
     * 
     * @type {string}
     * @memberof UsersApiCancelPendingUserInvitesApiUsersUserIdCancelInvitesPost
     */
    readonly organizationId: string
}

/**
 * Request parameters for deleteUserRolesApiUsersUserIdRolesDelete operation in UsersApi.
 * @export
 * @interface UsersApiDeleteUserRolesApiUsersUserIdRolesDeleteRequest
 */
export interface UsersApiDeleteUserRolesApiUsersUserIdRolesDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiDeleteUserRolesApiUsersUserIdRolesDelete
     */
    readonly userId: string

    /**
     * 
     * @type {Roles}
     * @memberof UsersApiDeleteUserRolesApiUsersUserIdRolesDelete
     */
    readonly role: Roles

    /**
     * 
     * @type {string}
     * @memberof UsersApiDeleteUserRolesApiUsersUserIdRolesDelete
     */
    readonly projectId: string
}

/**
 * Request parameters for getUserApiUsersUserIdGet operation in UsersApi.
 * @export
 * @interface UsersApiGetUserApiUsersUserIdGetRequest
 */
export interface UsersApiGetUserApiUsersUserIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiGetUserApiUsersUserIdGet
     */
    readonly userId: string
}

/**
 * Request parameters for patchUserRolesApiUsersUserIdRolesPatch operation in UsersApi.
 * @export
 * @interface UsersApiPatchUserRolesApiUsersUserIdRolesPatchRequest
 */
export interface UsersApiPatchUserRolesApiUsersUserIdRolesPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiPatchUserRolesApiUsersUserIdRolesPatch
     */
    readonly userId: string

    /**
     * 
     * @type {string}
     * @memberof UsersApiPatchUserRolesApiUsersUserIdRolesPatch
     */
    readonly projectId: string

    /**
     * 
     * @type {Array<Roles>}
     * @memberof UsersApiPatchUserRolesApiUsersUserIdRolesPatch
     */
    readonly roles: Array<Roles>
}

/**
 * Request parameters for patchUsersMeApiUsersMePatch operation in UsersApi.
 * @export
 * @interface UsersApiPatchUsersMeApiUsersMePatchRequest
 */
export interface UsersApiPatchUsersMeApiUsersMePatchRequest {
    /**
     * 
     * @type {UpdateUser}
     * @memberof UsersApiPatchUsersMeApiUsersMePatch
     */
    readonly updateUser: UpdateUser
}

/**
 * Request parameters for postUserRolesApiUsersUserIdRolesPost operation in UsersApi.
 * @export
 * @interface UsersApiPostUserRolesApiUsersUserIdRolesPostRequest
 */
export interface UsersApiPostUserRolesApiUsersUserIdRolesPostRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiPostUserRolesApiUsersUserIdRolesPost
     */
    readonly userId: string

    /**
     * 
     * @type {Roles}
     * @memberof UsersApiPostUserRolesApiUsersUserIdRolesPost
     */
    readonly role: Roles

    /**
     * 
     * @type {string}
     * @memberof UsersApiPostUserRolesApiUsersUserIdRolesPost
     */
    readonly projectId: string
}

/**
 * Request parameters for resendUserInviteApiUsersUserIdResendInvitePost operation in UsersApi.
 * @export
 * @interface UsersApiResendUserInviteApiUsersUserIdResendInvitePostRequest
 */
export interface UsersApiResendUserInviteApiUsersUserIdResendInvitePostRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiResendUserInviteApiUsersUserIdResendInvitePost
     */
    readonly userId: string

    /**
     * 
     * @type {string}
     * @memberof UsersApiResendUserInviteApiUsersUserIdResendInvitePost
     */
    readonly organizationId: string
}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary Cancel Pending User Invites
     * @param {UsersApiCancelPendingUserInvitesApiUsersUserIdCancelInvitesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public cancelPendingUserInvitesApiUsersUserIdCancelInvitesPost(requestParameters: UsersApiCancelPendingUserInvitesApiUsersUserIdCancelInvitesPostRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).cancelPendingUserInvitesApiUsersUserIdCancelInvitesPost(requestParameters.userId, requestParameters.organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete User Roles
     * @param {UsersApiDeleteUserRolesApiUsersUserIdRolesDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUserRolesApiUsersUserIdRolesDelete(requestParameters: UsersApiDeleteUserRolesApiUsersUserIdRolesDeleteRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).deleteUserRolesApiUsersUserIdRolesDelete(requestParameters.userId, requestParameters.role, requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the user with the given id.  Args:     user_id (uuid.UUID): The user id.  Returns:     schemas.User: The user with the given id.
     * @summary Get User
     * @param {UsersApiGetUserApiUsersUserIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserApiUsersUserIdGet(requestParameters: UsersApiGetUserApiUsersUserIdGetRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserApiUsersUserIdGet(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the current user.  Returns:     schemas.User: The current user.
     * @summary Get Users Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUsersMeApiUsersMeGet(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUsersMeApiUsersMeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch User Roles
     * @param {UsersApiPatchUserRolesApiUsersUserIdRolesPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public patchUserRolesApiUsersUserIdRolesPatch(requestParameters: UsersApiPatchUserRolesApiUsersUserIdRolesPatchRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).patchUserRolesApiUsersUserIdRolesPatch(requestParameters.userId, requestParameters.projectId, requestParameters.roles, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Allows users to update their information.  Args:     user (schemas.UpdateUser): The new user information.     current_user (schemas.User, optional): The current user. Defaults         to Depends(dependencies.get_current_active_user).     db (Session, optional): Database session. Defaults to Depends(get_db).
     * @summary Patch Users Me
     * @param {UsersApiPatchUsersMeApiUsersMePatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public patchUsersMeApiUsersMePatch(requestParameters: UsersApiPatchUsersMeApiUsersMePatchRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).patchUsersMeApiUsersMePatch(requestParameters.updateUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the last access of the user.  Args:     current_user (schemas.User, optional): The current user. Defaults         to Depends(dependencies.get_current_active_user).     db (Session, optional): Database session. Defaults to Depends(get_db).
     * @summary Patch Users Me Last Access
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public patchUsersMeLastAccessApiUsersMeLastAccessPatch(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).patchUsersMeLastAccessApiUsersMeLastAccessPatch(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post User Roles
     * @param {UsersApiPostUserRolesApiUsersUserIdRolesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public postUserRolesApiUsersUserIdRolesPost(requestParameters: UsersApiPostUserRolesApiUsersUserIdRolesPostRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).postUserRolesApiUsersUserIdRolesPost(requestParameters.userId, requestParameters.role, requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resend User Invite
     * @param {UsersApiResendUserInviteApiUsersUserIdResendInvitePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public resendUserInviteApiUsersUserIdResendInvitePost(requestParameters: UsersApiResendUserInviteApiUsersUserIdResendInvitePostRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).resendUserInviteApiUsersUserIdResendInvitePost(requestParameters.userId, requestParameters.organizationId, options).then((request) => request(this.axios, this.basePath));
    }
}

