import { useAuthStore } from '@/stores/AuthStore.ts';
import { useConfigStore } from '@/stores/ConfigStore.ts';

import { captureError } from '@/composables/Sentry.ts';
import { OrganizationRights, OrganizationRoles, Roles } from '@/client';
import { FORMAT_CONDITION, formatString } from './utils';

export const UpperCaseRoles = {
  Owner: formatString(Roles.Owner, [FORMAT_CONDITION.FIRST_LETTER_TO_UPPERCASE]),
  Editor: formatString(Roles.Editor, [FORMAT_CONDITION.FIRST_LETTER_TO_UPPERCASE]),
  Reviewer: formatString(Roles.Reviewer, [FORMAT_CONDITION.FIRST_LETTER_TO_UPPERCASE]),
  Auditor: formatString(Roles.Auditor, [FORMAT_CONDITION.FIRST_LETTER_TO_UPPERCASE]),
} as const;

export const isSuperAdmin = () => {
  const { user } = useAuthStore();

  if (!user) return false;

  return user.is_super_admin;
};

export const isOrgAdmin = () => {
  const { user } = useAuthStore();
  const { configData } = useConfigStore();

  if (!user || !configData || !user.organization_roles) return false;

  const currentOrganization = user.organization_roles.find(
    (role) => role.organization_id === configData.organization_id,
  );

  if (!currentOrganization) return false;

  return currentOrganization.role === OrganizationRoles.Admin;
};

export const hasProjectRights = (projectId: string, rightsRule: string) => {
  const { user } = useAuthStore();
  const { configData } = useConfigStore();

  if (!user || !configData) return false;

  // Check if user have full project acess right in organization_rights.
  if (Object.values(user.organization_rights).includes(OrganizationRights.ProjectsfullAccess)) {
    return true;
  }

  // Find related project by provided projectId in user.project_rights.
  const relatedProject = user.project_rights[projectId];

  if (!relatedProject) return false;

  // Check if user have provided righgtsRule in his project_rights.
  return Object.values(relatedProject).includes(rightsRule);
};

export const hasOrganizationRights = (rightsRule: string) => {
  const { user } = useAuthStore();

  if (!user) return false;

  return Object.values(user.organization_rights).includes(rightsRule);
};

export const userProjectRoles = async (projectId: string) => {
  const { user } = useAuthStore();

  if (!user || !('project_roles' in user)) {
    return [];
  }

  const relatedProjects = user.project_roles?.filter((role) => role.project_id === projectId);

  const rolesArray = relatedProjects?.map((role) => role.role);
  return rolesArray || [];
};

export const roleDescription = (role: string) => {
  switch (role) {
    case UpperCaseRoles.Owner:
    case Roles.Owner:
      return 'The person responsible for the project execution and delivery (e.g. project manager, lead data scientist)';
    case UpperCaseRoles.Editor:
    case Roles.Editor:
      return 'The person contributing to the project execution (e.g. data scientist, ML engineer, data engineer, data analyst)';
    case UpperCaseRoles.Reviewer:
    case Roles.Reviewer:
      return 'The person reviewing the project delivery (e.g. external stakeholder, project member)';
    case UpperCaseRoles.Auditor:
    case Roles.Auditor:
      return 'The person accountable for the fullfillment of Controls (e.g model risk, AI governance)';
  }
};

export const removeTokens = async () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
};

export function useAuth() {
  const logout = () => {
    removeTokens();
    localStorage.removeItem('user');
    window.location.href = `${import.meta.env.VITE_API_URL}/api/auth/logout`;
  };

  const toProblemPage = () => {
    removeTokens();
    localStorage.removeItem('user');
    window.location.href = `${import.meta.env.VITE_API_URL}/problem`;
  };

  const refreshToken = async () => {
    try {
      const authStore = useAuthStore();
      await authStore.getAccessTokenFromRefreshToken();
      const access_token = localStorage.getItem('access_token');
      if (!access_token) {
        logout();
      }
    } catch (error) {
      captureError(error, {
        message: 'Composable: Auth.ts',
      });
    }
  };

  return { logout, refreshToken, toProblemPage };
}
