import { useOrganizationStore } from '@/stores/OrganizationStore.ts';

export function OrganizationId(): string {
  const user = localStorage.getItem('user');
  if (!user) {
    return '';
  }

  const organization_id = JSON.parse(user).organization.id;

  return organization_id;
}

export function OrganizationCurrency(currency: string): string {
  switch (currency) {
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    default:
      return '€';
  }
}

export function AiProjectCreationAllowed(): boolean {
  const organizationStore = useOrganizationStore();

  if (!organizationStore.organizationQuota) return false;

  const plan_qouta_number = organizationStore.organizationQuota.plan_quota.projects;
  const used_ai_qouta_number =
    organizationStore.organizationQuota.used_quota.projects_ai_application;

  if (typeof plan_qouta_number === 'undefined' || plan_qouta_number === null) return true;

  return used_ai_qouta_number < plan_qouta_number;
}

export function OrgProjectCreationAllowed(): boolean {
  const organizationStore = useOrganizationStore();

  if (!organizationStore.organizationQuota) return false;

  const plan_qouta_number = organizationStore.organizationQuota.plan_quota.projects;
  const used_org_qouta_number =
    organizationStore.organizationQuota.used_quota.projects_organization;

  if (typeof plan_qouta_number === 'undefined' || plan_qouta_number === null) return true;

  return used_org_qouta_number < plan_qouta_number;
}
