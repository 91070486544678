import initApi from '@/client_heplers/utils.ts';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import { ConfigApi } from '@/client';

import { handleApiError } from '@/composables/ApiError.ts';
import type { AxiosError } from 'axios';
import type { OrganizationConfig, PatchOrganizationConfig } from '@/client';

import { OrganizationId } from '@/composables/Config.ts';

export const useConfigStore = defineStore('config', () => {
  const configData = ref<OrganizationConfig | null>(null);
  const vendorsEnabled = ref<boolean>(false);
  const usersEnabled = ref<boolean>(false);
  const evidenceScoringEnabled = ref<boolean>(false);
  const repositoryMonitoringEnabled = ref<boolean>(false);

  const getOrganizationConfig = async () => {
    try {
      const configApi = await initApi(ConfigApi);
      const organizationId = OrganizationId();

      const params = {
        organizationId: organizationId,
      };

      const response =
        await configApi?.getOrganizationConfigApiV1ConfigOrganizationsOrganizationIdGet(params);

      if (!response) return;

      configData.value = response.data;
      vendorsEnabled.value =
        response.data.flags?.vendor_management_enabled_for_entire_organization ?? true;
      usersEnabled.value =
        response.data.flags?.user_management_enabled_for_entire_organization ?? true;
      evidenceScoringEnabled.value =
        response.data.flags?.evidence_scoring_enabled_for_entire_organization ?? false;
      repositoryMonitoringEnabled.value =
        response.data.flags?.repository_monitoring_enabled_for_entire_organization ?? false;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const updateOrganizationConfig = async (data: PatchOrganizationConfig) => {
    try {
      const configApi = await initApi(ConfigApi);
      const organizationId = OrganizationId();

      const params = {
        organizationId: organizationId,
        patchOrganizationConfig: data,
      };

      const response =
        await configApi?.patchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatch(params);

      if (!response) return;

      configData.value = response.data;
      vendorsEnabled.value =
        response.data.flags?.vendor_management_enabled_for_entire_organization ?? false;
      usersEnabled.value =
        response.data.flags?.user_management_enabled_for_entire_organization ?? false;
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  return {
    configData,
    evidenceScoringEnabled,
    getOrganizationConfig,
    updateOrganizationConfig,
    usersEnabled,
    vendorsEnabled,
    repositoryMonitoringEnabled,
  };
});
