/* tslint:disable */
/* eslint-disable */
/**
 * Modulos Platform API
 * API for the Modulos Platform.
 *
 * The version of the OpenAPI document: 1.0.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { AiLifecycleDashboardContent } from '../model';
// @ts-ignore
import type { AssetCard } from '../model';
// @ts-ignore
import type { AssetCardNature } from '../model';
// @ts-ignore
import type { AssetCardPage } from '../model';
// @ts-ignore
import type { AssetCardTypes } from '../model';
// @ts-ignore
import type { Comment } from '../model';
// @ts-ignore
import type { Concept } from '../model';
// @ts-ignore
import type { Control } from '../model';
// @ts-ignore
import type { ControlDisplay } from '../model';
// @ts-ignore
import type { CreateAssetCard } from '../model';
// @ts-ignore
import type { CreateComment } from '../model';
// @ts-ignore
import type { CreateProject } from '../model';
// @ts-ignore
import type { CreateRisk } from '../model';
// @ts-ignore
import type { CreateTestingTest } from '../model';
// @ts-ignore
import type { EUAIActProjectSettings } from '../model';
// @ts-ignore
import type { EUAIActProjectSettingsCreate } from '../model';
// @ts-ignore
import type { ErrorResponse } from '../model';
// @ts-ignore
import type { Evidence } from '../model';
// @ts-ignore
import type { EvidencePage } from '../model';
// @ts-ignore
import type { EvidencePreview } from '../model';
// @ts-ignore
import type { Framework } from '../model';
// @ts-ignore
import type { HTTPValidationError } from '../model';
// @ts-ignore
import type { Logs } from '../model';
// @ts-ignore
import type { PageControl } from '../model';
// @ts-ignore
import type { PageControlList } from '../model';
// @ts-ignore
import type { PageFrameworkAggregator } from '../model';
// @ts-ignore
import type { PageRequirementAggregator } from '../model';
// @ts-ignore
import type { PageRisk } from '../model';
// @ts-ignore
import type { PageTestingMetric } from '../model';
// @ts-ignore
import type { PageTestingSource } from '../model';
// @ts-ignore
import type { PageTestingTest } from '../model';
// @ts-ignore
import type { ProjectAggregator } from '../model';
// @ts-ignore
import type { ProjectAiSystemLifecycleDashboard } from '../model';
// @ts-ignore
import type { ProjectCreator } from '../model';
// @ts-ignore
import type { ProjectDashboard } from '../model';
// @ts-ignore
import type { Review } from '../model';
// @ts-ignore
import type { ReviewDecision } from '../model';
// @ts-ignore
import type { ReviewRequest } from '../model';
// @ts-ignore
import type { RiskAggregator } from '../model';
// @ts-ignore
import type { RiskDisplay } from '../model';
// @ts-ignore
import type { RiskWithOverviewPage } from '../model';
// @ts-ignore
import type { Roles } from '../model';
// @ts-ignore
import type { Status } from '../model';
// @ts-ignore
import type { TestingLog } from '../model';
// @ts-ignore
import type { TestingLogCreate } from '../model';
// @ts-ignore
import type { TestingMetric } from '../model';
// @ts-ignore
import type { TestingMetricCreate } from '../model';
// @ts-ignore
import type { TestingSource } from '../model';
// @ts-ignore
import type { TestingTest } from '../model';
// @ts-ignore
import type { TestingTestAndResultPage } from '../model';
// @ts-ignore
import type { TestingTestResult } from '../model';
// @ts-ignore
import type { TestingTestResultPage } from '../model';
// @ts-ignore
import type { TestingTestStatus } from '../model';
// @ts-ignore
import type { UpdateProject } from '../model';
// @ts-ignore
import type { UpdateReviewRequest } from '../model';
// @ts-ignore
import type { UpdateTestingTest } from '../model';
// @ts-ignore
import type { UserProjectPage } from '../model';
/**
 * ProjectsApi - axios parameter creator
 * @export
 */
export const ProjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Testing Testing To Control
         * @param {string} projectId 
         * @param {string} controlId 
         * @param {string} testingTestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut: async (projectId: string, controlId: string, testingTestId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('addTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut', 'projectId', projectId)
            // verify required parameter 'controlId' is not null or undefined
            assertParamExists('addTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut', 'controlId', controlId)
            // verify required parameter 'testingTestId' is not null or undefined
            assertParamExists('addTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut', 'testingTestId', testingTestId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/tests/{testing_test_id}/controls/{control_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"control_id"}}`, encodeURIComponent(String(controlId)))
                .replace(`{${"testing_test_id"}}`, encodeURIComponent(String(testingTestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Cancel all pending reviews for a concept object.  Args:     concept_object (schemas.ControlAggregator | schemas.Risk | schemas.AssetCard, optional): The object. # noqa     db (Session, optional): The database session.     project (schemas.Project, optional): The project.     current_user (schemas.OrganizationUser, optional): The current user.
         * @summary Cancel Pending Reviews
         * @param {Concept} concept 
         * @param {string} objectId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDelete: async (concept: Concept, objectId: string, projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'concept' is not null or undefined
            assertParamExists('cancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDelete', 'concept', concept)
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('cancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDelete', 'objectId', objectId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('cancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDelete', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/reviews/{concept}/{object_id}`
                .replace(`{${"concept"}}`, encodeURIComponent(String(concept)))
                .replace(`{${"object_id"}}`, encodeURIComponent(String(objectId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new asset_card.  Args:     asset_card (schemas.CreateAssetCard): The asset_card to create.     response (Response): The response object.     db (Session, optional): The database session.     project (schemas.Project, optional): The project to create the asset_card for.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.AssetCard: The created AssetCard.
         * @summary Create Asset Card
         * @param {string} projectId 
         * @param {CreateAssetCard} createAssetCard 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAssetCardApiProjectsProjectIdAssetCardsPost: async (projectId: string, createAssetCard: CreateAssetCard, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createAssetCardApiProjectsProjectIdAssetCardsPost', 'projectId', projectId)
            // verify required parameter 'createAssetCard' is not null or undefined
            assertParamExists('createAssetCardApiProjectsProjectIdAssetCardsPost', 'createAssetCard', createAssetCard)
            const localVarPath = `/api/projects/{project_id}/asset_cards`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAssetCard, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Args:     object_type (str): The object type. (enums.Concept)     object_id (UUID): The object ID.     comment (schemas.Comment): The comment to create.     response (Response): The response object.     project (schemas.Project): The project.     db (Session, optional): The database session.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Control: The updated control.
         * @summary Create Comment
         * @param {string} objectId 
         * @param {Concept} objectType 
         * @param {string} projectId 
         * @param {CreateComment} createComment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPost: async (objectId: string, objectType: Concept, projectId: string, createComment: CreateComment, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('createCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPost', 'objectId', objectId)
            // verify required parameter 'objectType' is not null or undefined
            assertParamExists('createCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPost', 'objectType', objectType)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPost', 'projectId', projectId)
            // verify required parameter 'createComment' is not null or undefined
            assertParamExists('createCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPost', 'createComment', createComment)
            const localVarPath = `/api/projects/{project_id}/comments/{object_type}/{object_id}`
                .replace(`{${"object_id"}}`, encodeURIComponent(String(objectId)))
                .replace(`{${"object_type"}}`, encodeURIComponent(String(objectType)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createComment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the project EU AI Act settings.  Args:     settings (ProjectSettingsCreate): The project settings.     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     schemas.EUAIActProjectSettings: EU AI Act settings.
         * @summary Create Eu Ai Act Project Settings
         * @param {string} projectId 
         * @param {EUAIActProjectSettingsCreate} eUAIActProjectSettingsCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEuAiActProjectSettingsApiProjectsProjectIdEuAiActSettingsPost: async (projectId: string, eUAIActProjectSettingsCreate: EUAIActProjectSettingsCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createEuAiActProjectSettingsApiProjectsProjectIdEuAiActSettingsPost', 'projectId', projectId)
            // verify required parameter 'eUAIActProjectSettingsCreate' is not null or undefined
            assertParamExists('createEuAiActProjectSettingsApiProjectsProjectIdEuAiActSettingsPost', 'eUAIActProjectSettingsCreate', eUAIActProjectSettingsCreate)
            const localVarPath = `/api/projects/{project_id}/eu_ai_act_settings`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eUAIActProjectSettingsCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new project.  Args:     new_project (CreateProject): The new project.     db (Session): Database session.     user (User): The user creating the project.  Returns:     Project: The created project.
         * @summary Create Project
         * @param {CreateProject} createProject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectApiProjectsPost: async (createProject: CreateProject, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProject' is not null or undefined
            assertParamExists('createProjectApiProjectsPost', 'createProject', createProject)
            const localVarPath = `/api/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new framework for the project with the given id.  Args:     framework_codes (List[str]): The framework codes to create.     response (Response): The response object.     user (User): The user creating the framework.     project (Project): The project to create the framework for.     db (Session): Database session.     cache_service (Cache): The cache service.  Returns:     Framework: The created framework.
         * @summary Create Project Framework
         * @param {string} projectId 
         * @param {Array<string | null>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectFrameworkApiProjectsProjectIdFrameworksPost: async (projectId: string, requestBody: Array<string | null>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createProjectFrameworkApiProjectsProjectIdFrameworksPost', 'projectId', projectId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('createProjectFrameworkApiProjectsProjectIdFrameworksPost', 'requestBody', requestBody)
            const localVarPath = `/api/projects/{project_id}/frameworks`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new risk.  Args:     background_tasks:  The background tasks.     risk (schemas.CreateRisk): The risk to create.     response (Response): The response object.     db (Session, optional): The database session.     project (schemas.Project, optional): The project to create the risk for.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Risk: The created risk.
         * @summary Create Risk
         * @param {string} projectId 
         * @param {CreateRisk} createRisk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRiskApiProjectsProjectIdRisksPost: async (projectId: string, createRisk: CreateRisk, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createRiskApiProjectsProjectIdRisksPost', 'projectId', projectId)
            // verify required parameter 'createRisk' is not null or undefined
            assertParamExists('createRiskApiProjectsProjectIdRisksPost', 'createRisk', createRisk)
            const localVarPath = `/api/projects/{project_id}/risks`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRisk, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a monitoring datasource.  Args:     testing_log (TestingLogCreate): Monitoring datasource data.     project (Project): Project.     db (Session): Database session.  Returns:     TestingLog: Monitoring datasource.  Raises:     HTTPException: Project not found.
         * @summary Create Testing Log
         * @param {string} projectId 
         * @param {TestingLogCreate} testingLogCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestingLogApiV1ProjectsProjectIdTestingLogsPost: async (projectId: string, testingLogCreate: TestingLogCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createTestingLogApiV1ProjectsProjectIdTestingLogsPost', 'projectId', projectId)
            // verify required parameter 'testingLogCreate' is not null or undefined
            assertParamExists('createTestingLogApiV1ProjectsProjectIdTestingLogsPost', 'testingLogCreate', testingLogCreate)
            const localVarPath = `/api/v1/projects/{project_id}/testing/logs`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testingLogCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Testing Metric
         * @param {string} testingSourceId 
         * @param {string} projectId 
         * @param {TestingMetricCreate} testingMetricCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost: async (testingSourceId: string, projectId: string, testingMetricCreate: TestingMetricCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testingSourceId' is not null or undefined
            assertParamExists('createTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost', 'testingSourceId', testingSourceId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost', 'projectId', projectId)
            // verify required parameter 'testingMetricCreate' is not null or undefined
            assertParamExists('createTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost', 'testingMetricCreate', testingMetricCreate)
            const localVarPath = `/api/v1/projects/{project_id}/testing/sources/{testing_source_id}/metrics`
                .replace(`{${"testing_source_id"}}`, encodeURIComponent(String(testingSourceId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testingMetricCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Testing Test
         * @param {string} projectId 
         * @param {CreateTestingTest} createTestingTest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestingTestApiV1ProjectsProjectIdTestingTestsPost: async (projectId: string, createTestingTest: CreateTestingTest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createTestingTestApiV1ProjectsProjectIdTestingTestsPost', 'projectId', projectId)
            // verify required parameter 'createTestingTest' is not null or undefined
            assertParamExists('createTestingTestApiV1ProjectsProjectIdTestingTestsPost', 'createTestingTest', createTestingTest)
            const localVarPath = `/api/v1/projects/{project_id}/testing/tests`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTestingTest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.
         * @summary Delete Project
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectApiProjectsProjectIdDelete: async (projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('deleteProjectApiProjectsProjectIdDelete', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the project dashboard for the given project id.  Args:     project_id (UUID): The project ID.  Returns:     ProjectAiSystemLifecycleDashboard: The Lifecycle Dashboard.
         * @summary Get Ai System Life Cycle Dashboard
         * @param {string} projectId 
         * @param {AiLifecycleDashboardContent} [dashboardContent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGet: async (projectId: string, dashboardContent?: AiLifecycleDashboardContent, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/ai_system_life_cycle_dashboard`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (dashboardContent !== undefined) {
                localVarQueryParameter['dashboard_content'] = dashboardContent;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Args:     object_id (UUID): The object ID.     object_type (enums.Concept): The object type. (enums.Concept)     response (Response): The response object.     project (schemas.Project): The project.     db (Session, optional): The database session.  Returns:     schemas.Comment: The updated control.
         * @summary Get Comments
         * @param {string} objectId 
         * @param {Concept} objectType 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGet: async (objectId: string, objectType: Concept, projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('getCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGet', 'objectId', objectId)
            // verify required parameter 'objectType' is not null or undefined
            assertParamExists('getCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGet', 'objectType', objectType)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/comments/{object_type}/{object_id}`
                .replace(`{${"object_id"}}`, encodeURIComponent(String(objectId)))
                .replace(`{${"object_type"}}`, encodeURIComponent(String(objectType)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Control By Code
         * @param {string} controlCode 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getControlByCodeApiProjectsProjectIdControlsControlCodeGet: async (controlCode: string, projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlCode' is not null or undefined
            assertParamExists('getControlByCodeApiProjectsProjectIdControlsControlCodeGet', 'controlCode', controlCode)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getControlByCodeApiProjectsProjectIdControlsControlCodeGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/controls/{control_code}`
                .replace(`{${"control_code"}}`, encodeURIComponent(String(controlCode)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Controls By Testing Test
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet: async (projectId: string, testingTestId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet', 'projectId', projectId)
            // verify required parameter 'testingTestId' is not null or undefined
            assertParamExists('getControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet', 'testingTestId', testingTestId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/tests/{testing_test_id}/controls`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"testing_test_id"}}`, encodeURIComponent(String(testingTestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the project EU AI ActSettings.  Args:     settings (ProjectSettingsCreate): The project settings.     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     schemas.EUAIActProjectSettings: EU AI Act settings.
         * @summary Get Eu Ai Act Project Settings
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEuAiActProjectSettingsApiProjectsProjectIdEuAiActSettingsGet: async (projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getEuAiActProjectSettingsApiProjectsProjectIdEuAiActSettingsGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/eu_ai_act_settings`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the file types of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session. Returns:     List[schemas.FileType]: List of file types.
         * @summary Get Evidence File Types
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvidenceFileTypesApiProjectsProjectIdEvidenceFilesTypesGet: async (projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getEvidenceFileTypesApiProjectsProjectIdEvidenceFilesTypesGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/evidence/files/types`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the first 10 evidences (name, description and id) of the project with a name filter. This is used for a dropdown selection.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     name (str): Filter by evidence name.     params (Params): Pagination params.  Returns:     List[schemas.EvidencePreview]: List of evidences.
         * @summary Get Evidence List
         * @param {string} projectId 
         * @param {string | null} [name] Filter by evidence name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvidenceListApiProjectsProjectIdEvidenceListGet: async (projectId: string, name?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getEvidenceListApiProjectsProjectIdEvidenceListGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/evidence_list`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the evidences of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     name (str): Filter by evidence name.     params (Params): Pagination params.  Returns:     Page[schemas.Evidence]: Page of evidences.
         * @summary Get Evidences By Project Id
         * @param {string} projectId 
         * @param {string | null} [name] Filter by evidence name
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {Array<string>} [associatedControls] Filter by associated controls
         * @param {Array<string>} [associatedRisks] Filter by associated risks
         * @param {string | null} [startDate] Filter by creation date of the evidence
         * @param {string | null} [endDate] Filter by creation date of the evidence
         * @param {string | null} [fileType] Filter by file type (e.g. \&#39;Image\&#39;)
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvidencesByProjectIdApiProjectsProjectIdEvidenceGet: async (projectId: string, name?: string | null, sortBy?: string | null, descending?: boolean, associatedControls?: Array<string>, associatedRisks?: Array<string>, startDate?: string | null, endDate?: string | null, fileType?: string | null, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getEvidencesByProjectIdApiProjectsProjectIdEvidenceGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/evidence`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (associatedControls) {
                localVarQueryParameter['associated_controls'] = associatedControls;
            }

            if (associatedRisks) {
                localVarQueryParameter['associated_risks'] = associatedRisks;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }

            if (fileType !== undefined) {
                localVarQueryParameter['file_type'] = fileType;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the framework codes which has questionnaires.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     list[str]: framework codes.
         * @summary Get Frameworks Questionnaires
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFrameworksQuestionnairesApiProjectsProjectIdFrameworksQuestionnairesGet: async (projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getFrameworksQuestionnairesApiProjectsProjectIdFrameworksQuestionnairesGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/frameworks-questionnaires`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the logs for the given project and object id.  Args:     project_id (UUID): The project ID.     object_id (UUID): The object ID.  Returns:     List[Logs]: The logs.
         * @summary Get Logs
         * @param {string} objectId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogsApiProjectsProjectIdLogsObjectIdGet: async (objectId: string, projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('getLogsApiProjectsProjectIdLogsObjectIdGet', 'objectId', objectId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getLogsApiProjectsProjectIdLogsObjectIdGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/logs/{object_id}`
                .replace(`{${"object_id"}}`, encodeURIComponent(String(objectId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the project with the given id. Args:     project_id (UUID): The project ID.  Returns:     Project: The project.
         * @summary Get Project
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectApiProjectsProjectIdGet: async (projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectApiProjectsProjectIdGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the asset_cards of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     Page[schemas.AssetCardPage]: List of asset_cards.
         * @summary Get Project Asset Cards
         * @param {string} projectId 
         * @param {string | null} [name] Filter by asset_card name
         * @param {string | null} [status] Filter by asset_card status
         * @param {boolean} [isAssignedToMe] Filter by asset_cards assigned to me
         * @param {AssetCardTypes | null} [assetCardType] Filter by asset_card_type
         * @param {AssetCardNature | null} [assetCardNature] Filter by asset card nature
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectAssetCardsApiProjectsProjectIdAssetCardsGet: async (projectId: string, name?: string | null, status?: string | null, isAssignedToMe?: boolean, assetCardType?: AssetCardTypes | null, assetCardNature?: AssetCardNature | null, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectAssetCardsApiProjectsProjectIdAssetCardsGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/asset_cards`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (isAssignedToMe !== undefined) {
                localVarQueryParameter['is_assigned_to_me'] = isAssignedToMe;
            }

            if (assetCardType !== undefined) {
                localVarQueryParameter['asset_card_type'] = assetCardType;
            }

            if (assetCardNature !== undefined) {
                localVarQueryParameter['asset_card_nature'] = assetCardNature;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the controls of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     Page[schemas.ControlWithoutComponents]: List of controls.
         * @summary Get Project Controls
         * @param {string} projectId 
         * @param {Array<string>} [tags] 
         * @param {string | null} [name] Filter by control name
         * @param {string | null} [framework] Filter by framework code
         * @param {string | null} [requirement] Filter by requirement id
         * @param {string | null} [status] Filter by control status
         * @param {boolean} [isAssignedToMe] Filter by controls assigned to me
         * @param {boolean} [isAssignedToMeForReview] Filter by controls assigned to me for review
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectControlsApiProjectsProjectIdControlsGet: async (projectId: string, tags?: Array<string>, name?: string | null, framework?: string | null, requirement?: string | null, status?: string | null, isAssignedToMe?: boolean, isAssignedToMeForReview?: boolean, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectControlsApiProjectsProjectIdControlsGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/controls`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (framework !== undefined) {
                localVarQueryParameter['framework'] = framework;
            }

            if (requirement !== undefined) {
                localVarQueryParameter['requirement'] = requirement;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (isAssignedToMe !== undefined) {
                localVarQueryParameter['is_assigned_to_me'] = isAssignedToMe;
            }

            if (isAssignedToMeForReview !== undefined) {
                localVarQueryParameter['is_assigned_to_me_for_review'] = isAssignedToMeForReview;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Project Controls
         * @param {string} projectId 
         * @param {Status | null} [status] Filter by control status
         * @param {string | null} [name] 
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectControlsApiV1ProjectsProjectIdControlsGet: async (projectId: string, status?: Status | null, name?: string | null, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectControlsApiV1ProjectsProjectIdControlsGet', 'projectId', projectId)
            const localVarPath = `/api/v1/projects/{project_id}/controls`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the controls of the project with the given id which have at least one evidence.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     List[schemas.Risk]: List of risks.
         * @summary Get Project Controls With Evidences
         * @param {string} projectId 
         * @param {string | null} [name] Filter by control name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGet: async (projectId: string, name?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/controls_with_evidences`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the project dashboard for the given project id.  Args:     project_id (UUID): The project ID.  Returns:     Project: The project dashboard.
         * @summary Get Project Dashboard
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectDashboardApiProjectsProjectIdDashboardGet: async (projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectDashboardApiProjectsProjectIdDashboardGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/dashboard`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the frameworks of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     name (str): Filter by framework name.     params (Params): Pagination params.  Returns:     Page[schemas.FrameworkAggregator]: List of frameworks.
         * @summary Get Project Frameworks
         * @param {string} projectId 
         * @param {string | null} [name] Filter by framework name
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectFrameworksApiProjectsProjectIdFrameworksGet: async (projectId: string, name?: string | null, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectFrameworksApiProjectsProjectIdFrameworksGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/frameworks`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the project pdf for the given project id.  Args:     project_id (UUID): The project ID.  Returns:     FileResponse: The project pdf.
         * @summary Get Project Pdf
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectPdfApiProjectsProjectIdExportGet: async (projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectPdfApiProjectsProjectIdExportGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/export`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the requirements of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     name (str): Filter by requirement name.     framework (str): Filter by framework code.     status (str): Filter by requirement status.     params (Params): Pagination params.  Returns:     Page[schemas.Requirement]: Paginated List of requirements.
         * @summary Get Project Requirements
         * @param {string} projectId 
         * @param {string | null} [name] Filter by requirement name
         * @param {string | null} [framework] Filter by framework code
         * @param {string | null} [status] Filter by requirement status
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectRequirementsApiProjectsProjectIdRequirementsGet: async (projectId: string, name?: string | null, framework?: string | null, status?: string | null, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectRequirementsApiProjectsProjectIdRequirementsGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/requirements`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (framework !== undefined) {
                localVarQueryParameter['framework'] = framework;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the risks of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     Page[schemas.RiskWithoutComponentsAggregator]: List of risks.
         * @summary Get Project Risks
         * @param {string} projectId 
         * @param {string | null} [name] Filter by risk name
         * @param {string | null} [status] Filter by risk status
         * @param {boolean} [isAssignedToMe] Filter by risks assigned to me
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectRisksApiProjectsProjectIdRisksGet: async (projectId: string, name?: string | null, status?: string | null, isAssignedToMe?: boolean, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectRisksApiProjectsProjectIdRisksGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/risks`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (isAssignedToMe !== undefined) {
                localVarQueryParameter['is_assigned_to_me'] = isAssignedToMe;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the risks of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     Page[schemas.RiskWithoutComponentsAggregator]: List of risks.
         * @summary Get Project Risks List
         * @param {string} projectId 
         * @param {string | null} [name] Filter by risk name
         * @param {string | null} [status] Filter by risk status
         * @param {boolean} [isAssignedToMe] Filter by risks assigned to me
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectRisksListApiProjectsProjectIdRisksListGet: async (projectId: string, name?: string | null, status?: string | null, isAssignedToMe?: boolean, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectRisksListApiProjectsProjectIdRisksListGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/risks/list`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (isAssignedToMe !== undefined) {
                localVarQueryParameter['is_assigned_to_me'] = isAssignedToMe;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the risks of the project with the given id which have at least one evidence.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     List[schemas.Risk]: List of risks.
         * @summary Get Project Risks With Evidences
         * @param {string} projectId 
         * @param {string | null} [name] Filter by risk name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGet: async (projectId: string, name?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/risks_with_evidences`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Reviews For Concept Type
         * @param {Concept} concept 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewsForConceptTypeApiProjectsProjectIdReviewsConceptGet: async (concept: Concept, projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'concept' is not null or undefined
            assertParamExists('getReviewsForConceptTypeApiProjectsProjectIdReviewsConceptGet', 'concept', concept)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getReviewsForConceptTypeApiProjectsProjectIdReviewsConceptGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/reviews/{concept}`
                .replace(`{${"concept"}}`, encodeURIComponent(String(concept)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Reviews For Object Id
         * @param {string} objectId 
         * @param {Concept} concept 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGet: async (objectId: string, concept: Concept, projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('getReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGet', 'objectId', objectId)
            // verify required parameter 'concept' is not null or undefined
            assertParamExists('getReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGet', 'concept', concept)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/reviews/{concept}/{object_id}`
                .replace(`{${"object_id"}}`, encodeURIComponent(String(objectId)))
                .replace(`{${"concept"}}`, encodeURIComponent(String(concept)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Testing Metric
         * @param {string} testingSourceId 
         * @param {string} testingMetricId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet: async (testingSourceId: string, testingMetricId: string, projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testingSourceId' is not null or undefined
            assertParamExists('getTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet', 'testingSourceId', testingSourceId)
            // verify required parameter 'testingMetricId' is not null or undefined
            assertParamExists('getTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet', 'testingMetricId', testingMetricId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet', 'projectId', projectId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/sources/{testing_source_id}/metrics/{testing_metric_id}`
                .replace(`{${"testing_source_id"}}`, encodeURIComponent(String(testingSourceId)))
                .replace(`{${"testing_metric_id"}}`, encodeURIComponent(String(testingMetricId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Testing Source
         * @param {string} testingSourceId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet: async (testingSourceId: string, projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testingSourceId' is not null or undefined
            assertParamExists('getTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet', 'testingSourceId', testingSourceId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet', 'projectId', projectId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/sources/{testing_source_id}`
                .replace(`{${"testing_source_id"}}`, encodeURIComponent(String(testingSourceId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Testing Source Metrics
         * @param {string} testingSourceId 
         * @param {string} projectId 
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet: async (testingSourceId: string, projectId: string, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testingSourceId' is not null or undefined
            assertParamExists('getTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet', 'testingSourceId', testingSourceId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet', 'projectId', projectId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/sources/{testing_source_id}/metrics`
                .replace(`{${"testing_source_id"}}`, encodeURIComponent(String(testingSourceId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Testing Sources
         * @param {string} projectId 
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet: async (projectId: string, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet', 'projectId', projectId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/sources`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Testing Test And Latest Result
         * @param {string} projectId 
         * @param {string | null} [sortBy] Column name to sort by
         * @param {boolean} [descending] Sort in descending order
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {string | null} [name] Filter by name
         * @param {TestingTestStatus | null} [resultStatus] Filter by result status
         * @param {boolean | null} [status] Filter by test status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet: async (projectId: string, sortBy?: string | null, descending?: boolean, page?: number, size?: number, name?: string | null, resultStatus?: TestingTestStatus | null, status?: boolean | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet', 'projectId', projectId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/tests-results`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (resultStatus !== undefined) {
                localVarQueryParameter['result_status'] = resultStatus;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Testing Test
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet: async (projectId: string, testingTestId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet', 'projectId', projectId)
            // verify required parameter 'testingTestId' is not null or undefined
            assertParamExists('getTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet', 'testingTestId', testingTestId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/tests/{testing_test_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"testing_test_id"}}`, encodeURIComponent(String(testingTestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Testing Test Result
         * @param {string} testingTestResultId 
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet: async (testingTestResultId: string, projectId: string, testingTestId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testingTestResultId' is not null or undefined
            assertParamExists('getTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet', 'testingTestResultId', testingTestResultId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet', 'projectId', projectId)
            // verify required parameter 'testingTestId' is not null or undefined
            assertParamExists('getTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet', 'testingTestId', testingTestId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/tests/{testing_test_id}/results/{testing_test_result_id}`
                .replace(`{${"testing_test_result_id"}}`, encodeURIComponent(String(testingTestResultId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"testing_test_id"}}`, encodeURIComponent(String(testingTestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Testing Test Results
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {TestingTestStatus | null} [resultStatus] Filter by result status
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {string | null} [sortBy] Column name to sort by
         * @param {boolean} [descending] Sort in descending order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet: async (projectId: string, testingTestId: string, resultStatus?: TestingTestStatus | null, page?: number, size?: number, sortBy?: string | null, descending?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet', 'projectId', projectId)
            // verify required parameter 'testingTestId' is not null or undefined
            assertParamExists('getTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet', 'testingTestId', testingTestId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/tests/{testing_test_id}/results`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"testing_test_id"}}`, encodeURIComponent(String(testingTestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (resultStatus !== undefined) {
                localVarQueryParameter['result_status'] = resultStatus;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Testing Tests
         * @param {string} projectId 
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingTestsApiV1ProjectsProjectIdTestingTestsGet: async (projectId: string, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getTestingTestsApiV1ProjectsProjectIdTestingTestsGet', 'projectId', projectId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/tests`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the users of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     params (Params): Pagination params.     roles (List[enums.Roles]): Filter by role.     exclude_me (bool): Include current user.     sort_by (str): Sort by a field.     descending (bool): Sort order. If true, descending, otherwise ascending.  Returns:     Page[schemas.OrganizationUser]: List of users.
         * @summary Get User Of Project
         * @param {string} projectId 
         * @param {Array<Roles>} [roles] Filter by role.
         * @param {boolean} [excludeMe] Exclude current user.
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {string | null} [name] The user name filter.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserOfProjectApiProjectsProjectIdUsersGet: async (projectId: string, roles?: Array<Roles>, excludeMe?: boolean, sortBy?: string | null, descending?: boolean, name?: string | null, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getUserOfProjectApiProjectsProjectIdUsersGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/users`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (roles) {
                localVarQueryParameter['roles'] = roles;
            }

            if (excludeMe !== undefined) {
                localVarQueryParameter['exclude_me'] = excludeMe;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove Control From Testing Test
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete: async (projectId: string, testingTestId: string, controlId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('removeControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete', 'projectId', projectId)
            // verify required parameter 'testingTestId' is not null or undefined
            assertParamExists('removeControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete', 'testingTestId', testingTestId)
            // verify required parameter 'controlId' is not null or undefined
            assertParamExists('removeControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete', 'controlId', controlId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/tests/{testing_test_id}/controls/{control_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"testing_test_id"}}`, encodeURIComponent(String(testingTestId)))
                .replace(`{${"control_id"}}`, encodeURIComponent(String(controlId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Request access to the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     user (User): The user requesting access to the project.
         * @summary Request Access
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestAccessApiProjectsProjectIdRequestAccessPost: async (projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('requestAccessApiProjectsProjectIdRequestAccessPost', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/request_access`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Request reviews for a concept object.  Args:     review_request (schemas.ReviewRequest): The review request.     concept (enums.Concept): The concept of the object.     concept_object (schemas.ControlAggregator | schemas.Risk | schemas.AssetCard, optional): The object to request reviews for. # noqa     db (Session, optional): The database session.     project (schemas.Project, optional): The project.     current_user (schemas.OrganizationUser, optional): The current user.     force_update_status (bool, optional): Project owners can force update the         status of the object.  Returns:     List[schemas.Review]: The created reviews.
         * @summary Request Reviews
         * @param {Concept} concept 
         * @param {string} objectId 
         * @param {string} projectId 
         * @param {ReviewRequest} reviewRequest 
         * @param {boolean} [forceUpdateStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestReviewsApiProjectsProjectIdReviewsConceptObjectIdPost: async (concept: Concept, objectId: string, projectId: string, reviewRequest: ReviewRequest, forceUpdateStatus?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'concept' is not null or undefined
            assertParamExists('requestReviewsApiProjectsProjectIdReviewsConceptObjectIdPost', 'concept', concept)
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('requestReviewsApiProjectsProjectIdReviewsConceptObjectIdPost', 'objectId', objectId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('requestReviewsApiProjectsProjectIdReviewsConceptObjectIdPost', 'projectId', projectId)
            // verify required parameter 'reviewRequest' is not null or undefined
            assertParamExists('requestReviewsApiProjectsProjectIdReviewsConceptObjectIdPost', 'reviewRequest', reviewRequest)
            const localVarPath = `/api/projects/{project_id}/reviews/{concept}/{object_id}`
                .replace(`{${"concept"}}`, encodeURIComponent(String(concept)))
                .replace(`{${"object_id"}}`, encodeURIComponent(String(objectId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (forceUpdateStatus !== undefined) {
                localVarQueryParameter['force_update_status'] = forceUpdateStatus;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reviewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Run Testing Test
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost: async (projectId: string, testingTestId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('runTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost', 'projectId', projectId)
            // verify required parameter 'testingTestId' is not null or undefined
            assertParamExists('runTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost', 'testingTestId', testingTestId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/tests/{testing_test_id}/results`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"testing_test_id"}}`, encodeURIComponent(String(testingTestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Take a decision on a review for a concept object.  Args:     project_id (uuid.UUID): The project ID.     concept (enums.Concept): The concept of the object.     review_decision (schemas.ReviewDecision): The review decision.     concept_object (schemas.ControlAggregator | schemas.Risk | schemas.AssetCard, optional): The object. # noqa     db (Session, optional): The database session.     current_user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Review: The updated review.
         * @summary Take Review Decision
         * @param {string} projectId 
         * @param {Concept} concept 
         * @param {string} objectId 
         * @param {ReviewDecision} reviewDecision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        takeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatch: async (projectId: string, concept: Concept, objectId: string, reviewDecision: ReviewDecision, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('takeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatch', 'projectId', projectId)
            // verify required parameter 'concept' is not null or undefined
            assertParamExists('takeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatch', 'concept', concept)
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('takeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatch', 'objectId', objectId)
            // verify required parameter 'reviewDecision' is not null or undefined
            assertParamExists('takeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatch', 'reviewDecision', reviewDecision)
            const localVarPath = `/api/projects/{project_id}/reviews/{concept}/{object_id}/decision`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"concept"}}`, encodeURIComponent(String(concept)))
                .replace(`{${"object_id"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reviewDecision, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the project with the given id.  Args:     project_id (UUID): The project\'s id.     project_update (UpdateProject): The update to the project.     db (Session): Database session.  Returns:     schemas.Project: The updated project.
         * @summary Update Project
         * @param {string} projectId 
         * @param {UpdateProject} updateProject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectApiProjectsProjectIdPatch: async (projectId: string, updateProject: UpdateProject, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('updateProjectApiProjectsProjectIdPatch', 'projectId', projectId)
            // verify required parameter 'updateProject' is not null or undefined
            assertParamExists('updateProjectApiProjectsProjectIdPatch', 'updateProject', updateProject)
            const localVarPath = `/api/projects/{project_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the reviewers for a concept object.  Args:     review_request (schemas.UpdateReviewRequest): The update review request.     concept (enums.Concept): The concept of the object.     concept_object (schemas.ControlAggregator | schemas.Risk | schemas.AssetCard, optional): The object. # noqa     db (Session, optional): The database session.     project (schemas.Project, optional): The project.     current_user (schemas.OrganizationUser, optional): The current user.  Returns:     List[schemas.Review]: The updated reviews.
         * @summary Update Reviewers
         * @param {Concept} concept 
         * @param {string} objectId 
         * @param {string} projectId 
         * @param {UpdateReviewRequest} updateReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatch: async (concept: Concept, objectId: string, projectId: string, updateReviewRequest: UpdateReviewRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'concept' is not null or undefined
            assertParamExists('updateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatch', 'concept', concept)
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('updateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatch', 'objectId', objectId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('updateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatch', 'projectId', projectId)
            // verify required parameter 'updateReviewRequest' is not null or undefined
            assertParamExists('updateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatch', 'updateReviewRequest', updateReviewRequest)
            const localVarPath = `/api/projects/{project_id}/reviews/{concept}/{object_id}`
                .replace(`{${"concept"}}`, encodeURIComponent(String(concept)))
                .replace(`{${"object_id"}}`, encodeURIComponent(String(objectId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateReviewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Testing Test
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {UpdateTestingTest} updateTestingTest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch: async (projectId: string, testingTestId: string, updateTestingTest: UpdateTestingTest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('updateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch', 'projectId', projectId)
            // verify required parameter 'testingTestId' is not null or undefined
            assertParamExists('updateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch', 'testingTestId', testingTestId)
            // verify required parameter 'updateTestingTest' is not null or undefined
            assertParamExists('updateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch', 'updateTestingTest', updateTestingTest)
            const localVarPath = `/api/v1/projects/{project_id}/testing/tests/{testing_test_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"testing_test_id"}}`, encodeURIComponent(String(testingTestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTestingTest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Uploads the evidence file and creates an entry in the evidence database.  Args:     db (Session): Database session.     project_id (UUID): The project\'s id.     user (User): The user creating the evidence.     file (UploadFile): The file to upload.     component (Component): The component the evidence belongs to.  Returns:     schemas.Evidence: The created evidence.
         * @summary Upload And Create Evidence
         * @param {string} projectId 
         * @param {File} file 
         * @param {string} [name] The file name.
         * @param {string} [description] The description.
         * @param {string | null} [componentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAndCreateEvidenceApiProjectsProjectIdEvidencePost: async (projectId: string, file: File, name?: string, description?: string, componentId?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('uploadAndCreateEvidenceApiProjectsProjectIdEvidencePost', 'projectId', projectId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadAndCreateEvidenceApiProjectsProjectIdEvidencePost', 'file', file)
            const localVarPath = `/api/projects/{project_id}/evidence`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (componentId !== undefined) {
                localVarQueryParameter['component_id'] = componentId;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectsApi - functional programming interface
 * @export
 */
export const ProjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add Testing Testing To Control
         * @param {string} projectId 
         * @param {string} controlId 
         * @param {string} testingTestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut(projectId: string, controlId: string, testingTestId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestingTest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut(projectId, controlId, testingTestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.addTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Cancel all pending reviews for a concept object.  Args:     concept_object (schemas.ControlAggregator | schemas.Risk | schemas.AssetCard, optional): The object. # noqa     db (Session, optional): The database session.     project (schemas.Project, optional): The project.     current_user (schemas.OrganizationUser, optional): The current user.
         * @summary Cancel Pending Reviews
         * @param {Concept} concept 
         * @param {string} objectId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDelete(concept: Concept, objectId: string, projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDelete(concept, objectId, projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.cancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a new asset_card.  Args:     asset_card (schemas.CreateAssetCard): The asset_card to create.     response (Response): The response object.     db (Session, optional): The database session.     project (schemas.Project, optional): The project to create the asset_card for.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.AssetCard: The created AssetCard.
         * @summary Create Asset Card
         * @param {string} projectId 
         * @param {CreateAssetCard} createAssetCard 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAssetCardApiProjectsProjectIdAssetCardsPost(projectId: string, createAssetCard: CreateAssetCard, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetCard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAssetCardApiProjectsProjectIdAssetCardsPost(projectId, createAssetCard, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.createAssetCardApiProjectsProjectIdAssetCardsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Args:     object_type (str): The object type. (enums.Concept)     object_id (UUID): The object ID.     comment (schemas.Comment): The comment to create.     response (Response): The response object.     project (schemas.Project): The project.     db (Session, optional): The database session.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Control: The updated control.
         * @summary Create Comment
         * @param {string} objectId 
         * @param {Concept} objectType 
         * @param {string} projectId 
         * @param {CreateComment} createComment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPost(objectId: string, objectType: Concept, projectId: string, createComment: CreateComment, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Comment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPost(objectId, objectType, projectId, createComment, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.createCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the project EU AI Act settings.  Args:     settings (ProjectSettingsCreate): The project settings.     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     schemas.EUAIActProjectSettings: EU AI Act settings.
         * @summary Create Eu Ai Act Project Settings
         * @param {string} projectId 
         * @param {EUAIActProjectSettingsCreate} eUAIActProjectSettingsCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEuAiActProjectSettingsApiProjectsProjectIdEuAiActSettingsPost(projectId: string, eUAIActProjectSettingsCreate: EUAIActProjectSettingsCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EUAIActProjectSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEuAiActProjectSettingsApiProjectsProjectIdEuAiActSettingsPost(projectId, eUAIActProjectSettingsCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.createEuAiActProjectSettingsApiProjectsProjectIdEuAiActSettingsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a new project.  Args:     new_project (CreateProject): The new project.     db (Session): Database session.     user (User): The user creating the project.  Returns:     Project: The created project.
         * @summary Create Project
         * @param {CreateProject} createProject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProjectApiProjectsPost(createProject: CreateProject, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectCreator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProjectApiProjectsPost(createProject, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.createProjectApiProjectsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a new framework for the project with the given id.  Args:     framework_codes (List[str]): The framework codes to create.     response (Response): The response object.     user (User): The user creating the framework.     project (Project): The project to create the framework for.     db (Session): Database session.     cache_service (Cache): The cache service.  Returns:     Framework: The created framework.
         * @summary Create Project Framework
         * @param {string} projectId 
         * @param {Array<string | null>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProjectFrameworkApiProjectsProjectIdFrameworksPost(projectId: string, requestBody: Array<string | null>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Framework>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProjectFrameworkApiProjectsProjectIdFrameworksPost(projectId, requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.createProjectFrameworkApiProjectsProjectIdFrameworksPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a new risk.  Args:     background_tasks:  The background tasks.     risk (schemas.CreateRisk): The risk to create.     response (Response): The response object.     db (Session, optional): The database session.     project (schemas.Project, optional): The project to create the risk for.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Risk: The created risk.
         * @summary Create Risk
         * @param {string} projectId 
         * @param {CreateRisk} createRisk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRiskApiProjectsProjectIdRisksPost(projectId: string, createRisk: CreateRisk, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskAggregator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRiskApiProjectsProjectIdRisksPost(projectId, createRisk, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.createRiskApiProjectsProjectIdRisksPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create a monitoring datasource.  Args:     testing_log (TestingLogCreate): Monitoring datasource data.     project (Project): Project.     db (Session): Database session.  Returns:     TestingLog: Monitoring datasource.  Raises:     HTTPException: Project not found.
         * @summary Create Testing Log
         * @param {string} projectId 
         * @param {TestingLogCreate} testingLogCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTestingLogApiV1ProjectsProjectIdTestingLogsPost(projectId: string, testingLogCreate: TestingLogCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestingLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTestingLogApiV1ProjectsProjectIdTestingLogsPost(projectId, testingLogCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.createTestingLogApiV1ProjectsProjectIdTestingLogsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create Testing Metric
         * @param {string} testingSourceId 
         * @param {string} projectId 
         * @param {TestingMetricCreate} testingMetricCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost(testingSourceId: string, projectId: string, testingMetricCreate: TestingMetricCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestingMetric>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost(testingSourceId, projectId, testingMetricCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.createTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create Testing Test
         * @param {string} projectId 
         * @param {CreateTestingTest} createTestingTest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTestingTestApiV1ProjectsProjectIdTestingTestsPost(projectId: string, createTestingTest: CreateTestingTest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestingTest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTestingTestApiV1ProjectsProjectIdTestingTestsPost(projectId, createTestingTest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.createTestingTestApiV1ProjectsProjectIdTestingTestsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Deletes the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.
         * @summary Delete Project
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProjectApiProjectsProjectIdDelete(projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProjectApiProjectsProjectIdDelete(projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.deleteProjectApiProjectsProjectIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the project dashboard for the given project id.  Args:     project_id (UUID): The project ID.  Returns:     ProjectAiSystemLifecycleDashboard: The Lifecycle Dashboard.
         * @summary Get Ai System Life Cycle Dashboard
         * @param {string} projectId 
         * @param {AiLifecycleDashboardContent} [dashboardContent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGet(projectId: string, dashboardContent?: AiLifecycleDashboardContent, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectAiSystemLifecycleDashboard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGet(projectId, dashboardContent, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Args:     object_id (UUID): The object ID.     object_type (enums.Concept): The object type. (enums.Concept)     response (Response): The response object.     project (schemas.Project): The project.     db (Session, optional): The database session.  Returns:     schemas.Comment: The updated control.
         * @summary Get Comments
         * @param {string} objectId 
         * @param {Concept} objectType 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGet(objectId: string, objectType: Concept, projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Comment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGet(objectId, objectType, projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Control By Code
         * @param {string} controlCode 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getControlByCodeApiProjectsProjectIdControlsControlCodeGet(controlCode: string, projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Control>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getControlByCodeApiProjectsProjectIdControlsControlCodeGet(controlCode, projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getControlByCodeApiProjectsProjectIdControlsControlCodeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Controls By Testing Test
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet(projectId: string, testingTestId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Control>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet(projectId, testingTestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the project EU AI ActSettings.  Args:     settings (ProjectSettingsCreate): The project settings.     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     schemas.EUAIActProjectSettings: EU AI Act settings.
         * @summary Get Eu Ai Act Project Settings
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEuAiActProjectSettingsApiProjectsProjectIdEuAiActSettingsGet(projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EUAIActProjectSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEuAiActProjectSettingsApiProjectsProjectIdEuAiActSettingsGet(projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getEuAiActProjectSettingsApiProjectsProjectIdEuAiActSettingsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all the file types of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session. Returns:     List[schemas.FileType]: List of file types.
         * @summary Get Evidence File Types
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvidenceFileTypesApiProjectsProjectIdEvidenceFilesTypesGet(projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string | null>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEvidenceFileTypesApiProjectsProjectIdEvidenceFilesTypesGet(projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getEvidenceFileTypesApiProjectsProjectIdEvidenceFilesTypesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the first 10 evidences (name, description and id) of the project with a name filter. This is used for a dropdown selection.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     name (str): Filter by evidence name.     params (Params): Pagination params.  Returns:     List[schemas.EvidencePreview]: List of evidences.
         * @summary Get Evidence List
         * @param {string} projectId 
         * @param {string | null} [name] Filter by evidence name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvidenceListApiProjectsProjectIdEvidenceListGet(projectId: string, name?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EvidencePreview>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEvidenceListApiProjectsProjectIdEvidenceListGet(projectId, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getEvidenceListApiProjectsProjectIdEvidenceListGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all the evidences of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     name (str): Filter by evidence name.     params (Params): Pagination params.  Returns:     Page[schemas.Evidence]: Page of evidences.
         * @summary Get Evidences By Project Id
         * @param {string} projectId 
         * @param {string | null} [name] Filter by evidence name
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {Array<string>} [associatedControls] Filter by associated controls
         * @param {Array<string>} [associatedRisks] Filter by associated risks
         * @param {string | null} [startDate] Filter by creation date of the evidence
         * @param {string | null} [endDate] Filter by creation date of the evidence
         * @param {string | null} [fileType] Filter by file type (e.g. \&#39;Image\&#39;)
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvidencesByProjectIdApiProjectsProjectIdEvidenceGet(projectId: string, name?: string | null, sortBy?: string | null, descending?: boolean, associatedControls?: Array<string>, associatedRisks?: Array<string>, startDate?: string | null, endDate?: string | null, fileType?: string | null, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EvidencePage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEvidencesByProjectIdApiProjectsProjectIdEvidenceGet(projectId, name, sortBy, descending, associatedControls, associatedRisks, startDate, endDate, fileType, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getEvidencesByProjectIdApiProjectsProjectIdEvidenceGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the framework codes which has questionnaires.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     list[str]: framework codes.
         * @summary Get Frameworks Questionnaires
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFrameworksQuestionnairesApiProjectsProjectIdFrameworksQuestionnairesGet(projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string | null>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFrameworksQuestionnairesApiProjectsProjectIdFrameworksQuestionnairesGet(projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getFrameworksQuestionnairesApiProjectsProjectIdFrameworksQuestionnairesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the logs for the given project and object id.  Args:     project_id (UUID): The project ID.     object_id (UUID): The object ID.  Returns:     List[Logs]: The logs.
         * @summary Get Logs
         * @param {string} objectId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLogsApiProjectsProjectIdLogsObjectIdGet(objectId: string, projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Logs>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLogsApiProjectsProjectIdLogsObjectIdGet(objectId, projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getLogsApiProjectsProjectIdLogsObjectIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the project with the given id. Args:     project_id (UUID): The project ID.  Returns:     Project: The project.
         * @summary Get Project
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectApiProjectsProjectIdGet(projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectAggregator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectApiProjectsProjectIdGet(projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getProjectApiProjectsProjectIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all the asset_cards of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     Page[schemas.AssetCardPage]: List of asset_cards.
         * @summary Get Project Asset Cards
         * @param {string} projectId 
         * @param {string | null} [name] Filter by asset_card name
         * @param {string | null} [status] Filter by asset_card status
         * @param {boolean} [isAssignedToMe] Filter by asset_cards assigned to me
         * @param {AssetCardTypes | null} [assetCardType] Filter by asset_card_type
         * @param {AssetCardNature | null} [assetCardNature] Filter by asset card nature
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectAssetCardsApiProjectsProjectIdAssetCardsGet(projectId: string, name?: string | null, status?: string | null, isAssignedToMe?: boolean, assetCardType?: AssetCardTypes | null, assetCardNature?: AssetCardNature | null, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetCardPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectAssetCardsApiProjectsProjectIdAssetCardsGet(projectId, name, status, isAssignedToMe, assetCardType, assetCardNature, sortBy, descending, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getProjectAssetCardsApiProjectsProjectIdAssetCardsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all the controls of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     Page[schemas.ControlWithoutComponents]: List of controls.
         * @summary Get Project Controls
         * @param {string} projectId 
         * @param {Array<string>} [tags] 
         * @param {string | null} [name] Filter by control name
         * @param {string | null} [framework] Filter by framework code
         * @param {string | null} [requirement] Filter by requirement id
         * @param {string | null} [status] Filter by control status
         * @param {boolean} [isAssignedToMe] Filter by controls assigned to me
         * @param {boolean} [isAssignedToMeForReview] Filter by controls assigned to me for review
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectControlsApiProjectsProjectIdControlsGet(projectId: string, tags?: Array<string>, name?: string | null, framework?: string | null, requirement?: string | null, status?: string | null, isAssignedToMe?: boolean, isAssignedToMeForReview?: boolean, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageControlList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectControlsApiProjectsProjectIdControlsGet(projectId, tags, name, framework, requirement, status, isAssignedToMe, isAssignedToMeForReview, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getProjectControlsApiProjectsProjectIdControlsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Project Controls
         * @param {string} projectId 
         * @param {Status | null} [status] Filter by control status
         * @param {string | null} [name] 
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectControlsApiV1ProjectsProjectIdControlsGet(projectId: string, status?: Status | null, name?: string | null, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageControl>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectControlsApiV1ProjectsProjectIdControlsGet(projectId, status, name, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getProjectControlsApiV1ProjectsProjectIdControlsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all the controls of the project with the given id which have at least one evidence.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     List[schemas.Risk]: List of risks.
         * @summary Get Project Controls With Evidences
         * @param {string} projectId 
         * @param {string | null} [name] Filter by control name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGet(projectId: string, name?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlDisplay>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGet(projectId, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the project dashboard for the given project id.  Args:     project_id (UUID): The project ID.  Returns:     Project: The project dashboard.
         * @summary Get Project Dashboard
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectDashboardApiProjectsProjectIdDashboardGet(projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectDashboard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectDashboardApiProjectsProjectIdDashboardGet(projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getProjectDashboardApiProjectsProjectIdDashboardGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all the frameworks of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     name (str): Filter by framework name.     params (Params): Pagination params.  Returns:     Page[schemas.FrameworkAggregator]: List of frameworks.
         * @summary Get Project Frameworks
         * @param {string} projectId 
         * @param {string | null} [name] Filter by framework name
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectFrameworksApiProjectsProjectIdFrameworksGet(projectId: string, name?: string | null, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageFrameworkAggregator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectFrameworksApiProjectsProjectIdFrameworksGet(projectId, name, sortBy, descending, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getProjectFrameworksApiProjectsProjectIdFrameworksGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the project pdf for the given project id.  Args:     project_id (UUID): The project ID.  Returns:     FileResponse: The project pdf.
         * @summary Get Project Pdf
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectPdfApiProjectsProjectIdExportGet(projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectPdfApiProjectsProjectIdExportGet(projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getProjectPdfApiProjectsProjectIdExportGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all the requirements of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     name (str): Filter by requirement name.     framework (str): Filter by framework code.     status (str): Filter by requirement status.     params (Params): Pagination params.  Returns:     Page[schemas.Requirement]: Paginated List of requirements.
         * @summary Get Project Requirements
         * @param {string} projectId 
         * @param {string | null} [name] Filter by requirement name
         * @param {string | null} [framework] Filter by framework code
         * @param {string | null} [status] Filter by requirement status
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectRequirementsApiProjectsProjectIdRequirementsGet(projectId: string, name?: string | null, framework?: string | null, status?: string | null, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageRequirementAggregator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectRequirementsApiProjectsProjectIdRequirementsGet(projectId, name, framework, status, sortBy, descending, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getProjectRequirementsApiProjectsProjectIdRequirementsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all the risks of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     Page[schemas.RiskWithoutComponentsAggregator]: List of risks.
         * @summary Get Project Risks
         * @param {string} projectId 
         * @param {string | null} [name] Filter by risk name
         * @param {string | null} [status] Filter by risk status
         * @param {boolean} [isAssignedToMe] Filter by risks assigned to me
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectRisksApiProjectsProjectIdRisksGet(projectId: string, name?: string | null, status?: string | null, isAssignedToMe?: boolean, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageRisk>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectRisksApiProjectsProjectIdRisksGet(projectId, name, status, isAssignedToMe, sortBy, descending, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getProjectRisksApiProjectsProjectIdRisksGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all the risks of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     Page[schemas.RiskWithoutComponentsAggregator]: List of risks.
         * @summary Get Project Risks List
         * @param {string} projectId 
         * @param {string | null} [name] Filter by risk name
         * @param {string | null} [status] Filter by risk status
         * @param {boolean} [isAssignedToMe] Filter by risks assigned to me
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectRisksListApiProjectsProjectIdRisksListGet(projectId: string, name?: string | null, status?: string | null, isAssignedToMe?: boolean, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskWithOverviewPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectRisksListApiProjectsProjectIdRisksListGet(projectId, name, status, isAssignedToMe, sortBy, descending, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getProjectRisksListApiProjectsProjectIdRisksListGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all the risks of the project with the given id which have at least one evidence.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     List[schemas.Risk]: List of risks.
         * @summary Get Project Risks With Evidences
         * @param {string} projectId 
         * @param {string | null} [name] Filter by risk name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGet(projectId: string, name?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RiskDisplay>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGet(projectId, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Reviews For Concept Type
         * @param {Concept} concept 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReviewsForConceptTypeApiProjectsProjectIdReviewsConceptGet(concept: Concept, projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Review>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReviewsForConceptTypeApiProjectsProjectIdReviewsConceptGet(concept, projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getReviewsForConceptTypeApiProjectsProjectIdReviewsConceptGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Reviews For Object Id
         * @param {string} objectId 
         * @param {Concept} concept 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGet(objectId: string, concept: Concept, projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Review>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGet(objectId, concept, projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Testing Metric
         * @param {string} testingSourceId 
         * @param {string} testingMetricId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet(testingSourceId: string, testingMetricId: string, projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestingMetric>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet(testingSourceId, testingMetricId, projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Testing Source
         * @param {string} testingSourceId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet(testingSourceId: string, projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestingSource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet(testingSourceId, projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Testing Source Metrics
         * @param {string} testingSourceId 
         * @param {string} projectId 
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet(testingSourceId: string, projectId: string, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTestingMetric>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet(testingSourceId, projectId, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Testing Sources
         * @param {string} projectId 
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet(projectId: string, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTestingSource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet(projectId, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Testing Test And Latest Result
         * @param {string} projectId 
         * @param {string | null} [sortBy] Column name to sort by
         * @param {boolean} [descending] Sort in descending order
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {string | null} [name] Filter by name
         * @param {TestingTestStatus | null} [resultStatus] Filter by result status
         * @param {boolean | null} [status] Filter by test status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet(projectId: string, sortBy?: string | null, descending?: boolean, page?: number, size?: number, name?: string | null, resultStatus?: TestingTestStatus | null, status?: boolean | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestingTestAndResultPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet(projectId, sortBy, descending, page, size, name, resultStatus, status, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Testing Test
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet(projectId: string, testingTestId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestingTest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet(projectId, testingTestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Testing Test Result
         * @param {string} testingTestResultId 
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet(testingTestResultId: string, projectId: string, testingTestId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestingTestResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet(testingTestResultId, projectId, testingTestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Testing Test Results
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {TestingTestStatus | null} [resultStatus] Filter by result status
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {string | null} [sortBy] Column name to sort by
         * @param {boolean} [descending] Sort in descending order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet(projectId: string, testingTestId: string, resultStatus?: TestingTestStatus | null, page?: number, size?: number, sortBy?: string | null, descending?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestingTestResultPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet(projectId, testingTestId, resultStatus, page, size, sortBy, descending, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Testing Tests
         * @param {string} projectId 
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestingTestsApiV1ProjectsProjectIdTestingTestsGet(projectId: string, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTestingTest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestingTestsApiV1ProjectsProjectIdTestingTestsGet(projectId, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getTestingTestsApiV1ProjectsProjectIdTestingTestsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all the users of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     params (Params): Pagination params.     roles (List[enums.Roles]): Filter by role.     exclude_me (bool): Include current user.     sort_by (str): Sort by a field.     descending (bool): Sort order. If true, descending, otherwise ascending.  Returns:     Page[schemas.OrganizationUser]: List of users.
         * @summary Get User Of Project
         * @param {string} projectId 
         * @param {Array<Roles>} [roles] Filter by role.
         * @param {boolean} [excludeMe] Exclude current user.
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {string | null} [name] The user name filter.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserOfProjectApiProjectsProjectIdUsersGet(projectId: string, roles?: Array<Roles>, excludeMe?: boolean, sortBy?: string | null, descending?: boolean, name?: string | null, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProjectPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserOfProjectApiProjectsProjectIdUsersGet(projectId, roles, excludeMe, sortBy, descending, name, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getUserOfProjectApiProjectsProjectIdUsersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Remove Control From Testing Test
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete(projectId: string, testingTestId: string, controlId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete(projectId, testingTestId, controlId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.removeControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Request access to the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     user (User): The user requesting access to the project.
         * @summary Request Access
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestAccessApiProjectsProjectIdRequestAccessPost(projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestAccessApiProjectsProjectIdRequestAccessPost(projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.requestAccessApiProjectsProjectIdRequestAccessPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Request reviews for a concept object.  Args:     review_request (schemas.ReviewRequest): The review request.     concept (enums.Concept): The concept of the object.     concept_object (schemas.ControlAggregator | schemas.Risk | schemas.AssetCard, optional): The object to request reviews for. # noqa     db (Session, optional): The database session.     project (schemas.Project, optional): The project.     current_user (schemas.OrganizationUser, optional): The current user.     force_update_status (bool, optional): Project owners can force update the         status of the object.  Returns:     List[schemas.Review]: The created reviews.
         * @summary Request Reviews
         * @param {Concept} concept 
         * @param {string} objectId 
         * @param {string} projectId 
         * @param {ReviewRequest} reviewRequest 
         * @param {boolean} [forceUpdateStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestReviewsApiProjectsProjectIdReviewsConceptObjectIdPost(concept: Concept, objectId: string, projectId: string, reviewRequest: ReviewRequest, forceUpdateStatus?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Review>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestReviewsApiProjectsProjectIdReviewsConceptObjectIdPost(concept, objectId, projectId, reviewRequest, forceUpdateStatus, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.requestReviewsApiProjectsProjectIdReviewsConceptObjectIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Run Testing Test
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost(projectId: string, testingTestId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestingTestResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost(projectId, testingTestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.runTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Take a decision on a review for a concept object.  Args:     project_id (uuid.UUID): The project ID.     concept (enums.Concept): The concept of the object.     review_decision (schemas.ReviewDecision): The review decision.     concept_object (schemas.ControlAggregator | schemas.Risk | schemas.AssetCard, optional): The object. # noqa     db (Session, optional): The database session.     current_user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Review: The updated review.
         * @summary Take Review Decision
         * @param {string} projectId 
         * @param {Concept} concept 
         * @param {string} objectId 
         * @param {ReviewDecision} reviewDecision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async takeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatch(projectId: string, concept: Concept, objectId: string, reviewDecision: ReviewDecision, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Review>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.takeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatch(projectId, concept, objectId, reviewDecision, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.takeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the project with the given id.  Args:     project_id (UUID): The project\'s id.     project_update (UpdateProject): The update to the project.     db (Session): Database session.  Returns:     schemas.Project: The updated project.
         * @summary Update Project
         * @param {string} projectId 
         * @param {UpdateProject} updateProject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProjectApiProjectsProjectIdPatch(projectId: string, updateProject: UpdateProject, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectAggregator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProjectApiProjectsProjectIdPatch(projectId, updateProject, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.updateProjectApiProjectsProjectIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update the reviewers for a concept object.  Args:     review_request (schemas.UpdateReviewRequest): The update review request.     concept (enums.Concept): The concept of the object.     concept_object (schemas.ControlAggregator | schemas.Risk | schemas.AssetCard, optional): The object. # noqa     db (Session, optional): The database session.     project (schemas.Project, optional): The project.     current_user (schemas.OrganizationUser, optional): The current user.  Returns:     List[schemas.Review]: The updated reviews.
         * @summary Update Reviewers
         * @param {Concept} concept 
         * @param {string} objectId 
         * @param {string} projectId 
         * @param {UpdateReviewRequest} updateReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatch(concept: Concept, objectId: string, projectId: string, updateReviewRequest: UpdateReviewRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Review>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatch(concept, objectId, projectId, updateReviewRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.updateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Testing Test
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {UpdateTestingTest} updateTestingTest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch(projectId: string, testingTestId: string, updateTestingTest: UpdateTestingTest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestingTest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch(projectId, testingTestId, updateTestingTest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.updateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Uploads the evidence file and creates an entry in the evidence database.  Args:     db (Session): Database session.     project_id (UUID): The project\'s id.     user (User): The user creating the evidence.     file (UploadFile): The file to upload.     component (Component): The component the evidence belongs to.  Returns:     schemas.Evidence: The created evidence.
         * @summary Upload And Create Evidence
         * @param {string} projectId 
         * @param {File} file 
         * @param {string} [name] The file name.
         * @param {string} [description] The description.
         * @param {string | null} [componentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadAndCreateEvidenceApiProjectsProjectIdEvidencePost(projectId: string, file: File, name?: string, description?: string, componentId?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Evidence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadAndCreateEvidenceApiProjectsProjectIdEvidencePost(projectId, file, name, description, componentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.uploadAndCreateEvidenceApiProjectsProjectIdEvidencePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProjectsApi - factory interface
 * @export
 */
export const ProjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectsApiFp(configuration)
    return {
        /**
         * 
         * @summary Add Testing Testing To Control
         * @param {ProjectsApiAddTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut(requestParameters: ProjectsApiAddTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<TestingTest> {
            return localVarFp.addTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut(requestParameters.projectId, requestParameters.controlId, requestParameters.testingTestId, options).then((request) => request(axios, basePath));
        },
        /**
         * Cancel all pending reviews for a concept object.  Args:     concept_object (schemas.ControlAggregator | schemas.Risk | schemas.AssetCard, optional): The object. # noqa     db (Session, optional): The database session.     project (schemas.Project, optional): The project.     current_user (schemas.OrganizationUser, optional): The current user.
         * @summary Cancel Pending Reviews
         * @param {ProjectsApiCancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDelete(requestParameters: ProjectsApiCancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.cancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDelete(requestParameters.concept, requestParameters.objectId, requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new asset_card.  Args:     asset_card (schemas.CreateAssetCard): The asset_card to create.     response (Response): The response object.     db (Session, optional): The database session.     project (schemas.Project, optional): The project to create the asset_card for.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.AssetCard: The created AssetCard.
         * @summary Create Asset Card
         * @param {ProjectsApiCreateAssetCardApiProjectsProjectIdAssetCardsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAssetCardApiProjectsProjectIdAssetCardsPost(requestParameters: ProjectsApiCreateAssetCardApiProjectsProjectIdAssetCardsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetCard> {
            return localVarFp.createAssetCardApiProjectsProjectIdAssetCardsPost(requestParameters.projectId, requestParameters.createAssetCard, options).then((request) => request(axios, basePath));
        },
        /**
         * Args:     object_type (str): The object type. (enums.Concept)     object_id (UUID): The object ID.     comment (schemas.Comment): The comment to create.     response (Response): The response object.     project (schemas.Project): The project.     db (Session, optional): The database session.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Control: The updated control.
         * @summary Create Comment
         * @param {ProjectsApiCreateCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPost(requestParameters: ProjectsApiCreateCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<Comment> {
            return localVarFp.createCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPost(requestParameters.objectId, requestParameters.objectType, requestParameters.projectId, requestParameters.createComment, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the project EU AI Act settings.  Args:     settings (ProjectSettingsCreate): The project settings.     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     schemas.EUAIActProjectSettings: EU AI Act settings.
         * @summary Create Eu Ai Act Project Settings
         * @param {ProjectsApiCreateEuAiActProjectSettingsApiProjectsProjectIdEuAiActSettingsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEuAiActProjectSettingsApiProjectsProjectIdEuAiActSettingsPost(requestParameters: ProjectsApiCreateEuAiActProjectSettingsApiProjectsProjectIdEuAiActSettingsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<EUAIActProjectSettings> {
            return localVarFp.createEuAiActProjectSettingsApiProjectsProjectIdEuAiActSettingsPost(requestParameters.projectId, requestParameters.eUAIActProjectSettingsCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new project.  Args:     new_project (CreateProject): The new project.     db (Session): Database session.     user (User): The user creating the project.  Returns:     Project: The created project.
         * @summary Create Project
         * @param {ProjectsApiCreateProjectApiProjectsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectApiProjectsPost(requestParameters: ProjectsApiCreateProjectApiProjectsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProjectCreator> {
            return localVarFp.createProjectApiProjectsPost(requestParameters.createProject, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new framework for the project with the given id.  Args:     framework_codes (List[str]): The framework codes to create.     response (Response): The response object.     user (User): The user creating the framework.     project (Project): The project to create the framework for.     db (Session): Database session.     cache_service (Cache): The cache service.  Returns:     Framework: The created framework.
         * @summary Create Project Framework
         * @param {ProjectsApiCreateProjectFrameworkApiProjectsProjectIdFrameworksPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectFrameworkApiProjectsProjectIdFrameworksPost(requestParameters: ProjectsApiCreateProjectFrameworkApiProjectsProjectIdFrameworksPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Framework>> {
            return localVarFp.createProjectFrameworkApiProjectsProjectIdFrameworksPost(requestParameters.projectId, requestParameters.requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new risk.  Args:     background_tasks:  The background tasks.     risk (schemas.CreateRisk): The risk to create.     response (Response): The response object.     db (Session, optional): The database session.     project (schemas.Project, optional): The project to create the risk for.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Risk: The created risk.
         * @summary Create Risk
         * @param {ProjectsApiCreateRiskApiProjectsProjectIdRisksPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRiskApiProjectsProjectIdRisksPost(requestParameters: ProjectsApiCreateRiskApiProjectsProjectIdRisksPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<RiskAggregator> {
            return localVarFp.createRiskApiProjectsProjectIdRisksPost(requestParameters.projectId, requestParameters.createRisk, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a monitoring datasource.  Args:     testing_log (TestingLogCreate): Monitoring datasource data.     project (Project): Project.     db (Session): Database session.  Returns:     TestingLog: Monitoring datasource.  Raises:     HTTPException: Project not found.
         * @summary Create Testing Log
         * @param {ProjectsApiCreateTestingLogApiV1ProjectsProjectIdTestingLogsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestingLogApiV1ProjectsProjectIdTestingLogsPost(requestParameters: ProjectsApiCreateTestingLogApiV1ProjectsProjectIdTestingLogsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TestingLog> {
            return localVarFp.createTestingLogApiV1ProjectsProjectIdTestingLogsPost(requestParameters.projectId, requestParameters.testingLogCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Testing Metric
         * @param {ProjectsApiCreateTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost(requestParameters: ProjectsApiCreateTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TestingMetric> {
            return localVarFp.createTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost(requestParameters.testingSourceId, requestParameters.projectId, requestParameters.testingMetricCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Testing Test
         * @param {ProjectsApiCreateTestingTestApiV1ProjectsProjectIdTestingTestsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestingTestApiV1ProjectsProjectIdTestingTestsPost(requestParameters: ProjectsApiCreateTestingTestApiV1ProjectsProjectIdTestingTestsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TestingTest> {
            return localVarFp.createTestingTestApiV1ProjectsProjectIdTestingTestsPost(requestParameters.projectId, requestParameters.createTestingTest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.
         * @summary Delete Project
         * @param {ProjectsApiDeleteProjectApiProjectsProjectIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectApiProjectsProjectIdDelete(requestParameters: ProjectsApiDeleteProjectApiProjectsProjectIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteProjectApiProjectsProjectIdDelete(requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the project dashboard for the given project id.  Args:     project_id (UUID): The project ID.  Returns:     ProjectAiSystemLifecycleDashboard: The Lifecycle Dashboard.
         * @summary Get Ai System Life Cycle Dashboard
         * @param {ProjectsApiGetAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGet(requestParameters: ProjectsApiGetAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProjectAiSystemLifecycleDashboard> {
            return localVarFp.getAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGet(requestParameters.projectId, requestParameters.dashboardContent, options).then((request) => request(axios, basePath));
        },
        /**
         * Args:     object_id (UUID): The object ID.     object_type (enums.Concept): The object type. (enums.Concept)     response (Response): The response object.     project (schemas.Project): The project.     db (Session, optional): The database session.  Returns:     schemas.Comment: The updated control.
         * @summary Get Comments
         * @param {ProjectsApiGetCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGet(requestParameters: ProjectsApiGetCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Comment>> {
            return localVarFp.getCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGet(requestParameters.objectId, requestParameters.objectType, requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Control By Code
         * @param {ProjectsApiGetControlByCodeApiProjectsProjectIdControlsControlCodeGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getControlByCodeApiProjectsProjectIdControlsControlCodeGet(requestParameters: ProjectsApiGetControlByCodeApiProjectsProjectIdControlsControlCodeGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Control> {
            return localVarFp.getControlByCodeApiProjectsProjectIdControlsControlCodeGet(requestParameters.controlCode, requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Controls By Testing Test
         * @param {ProjectsApiGetControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet(requestParameters: ProjectsApiGetControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Control>> {
            return localVarFp.getControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet(requestParameters.projectId, requestParameters.testingTestId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the project EU AI ActSettings.  Args:     settings (ProjectSettingsCreate): The project settings.     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     schemas.EUAIActProjectSettings: EU AI Act settings.
         * @summary Get Eu Ai Act Project Settings
         * @param {ProjectsApiGetEuAiActProjectSettingsApiProjectsProjectIdEuAiActSettingsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEuAiActProjectSettingsApiProjectsProjectIdEuAiActSettingsGet(requestParameters: ProjectsApiGetEuAiActProjectSettingsApiProjectsProjectIdEuAiActSettingsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<EUAIActProjectSettings> {
            return localVarFp.getEuAiActProjectSettingsApiProjectsProjectIdEuAiActSettingsGet(requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the file types of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session. Returns:     List[schemas.FileType]: List of file types.
         * @summary Get Evidence File Types
         * @param {ProjectsApiGetEvidenceFileTypesApiProjectsProjectIdEvidenceFilesTypesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvidenceFileTypesApiProjectsProjectIdEvidenceFilesTypesGet(requestParameters: ProjectsApiGetEvidenceFileTypesApiProjectsProjectIdEvidenceFilesTypesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<string | null>> {
            return localVarFp.getEvidenceFileTypesApiProjectsProjectIdEvidenceFilesTypesGet(requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the first 10 evidences (name, description and id) of the project with a name filter. This is used for a dropdown selection.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     name (str): Filter by evidence name.     params (Params): Pagination params.  Returns:     List[schemas.EvidencePreview]: List of evidences.
         * @summary Get Evidence List
         * @param {ProjectsApiGetEvidenceListApiProjectsProjectIdEvidenceListGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvidenceListApiProjectsProjectIdEvidenceListGet(requestParameters: ProjectsApiGetEvidenceListApiProjectsProjectIdEvidenceListGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<EvidencePreview>> {
            return localVarFp.getEvidenceListApiProjectsProjectIdEvidenceListGet(requestParameters.projectId, requestParameters.name, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the evidences of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     name (str): Filter by evidence name.     params (Params): Pagination params.  Returns:     Page[schemas.Evidence]: Page of evidences.
         * @summary Get Evidences By Project Id
         * @param {ProjectsApiGetEvidencesByProjectIdApiProjectsProjectIdEvidenceGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvidencesByProjectIdApiProjectsProjectIdEvidenceGet(requestParameters: ProjectsApiGetEvidencesByProjectIdApiProjectsProjectIdEvidenceGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<EvidencePage> {
            return localVarFp.getEvidencesByProjectIdApiProjectsProjectIdEvidenceGet(requestParameters.projectId, requestParameters.name, requestParameters.sortBy, requestParameters.descending, requestParameters.associatedControls, requestParameters.associatedRisks, requestParameters.startDate, requestParameters.endDate, requestParameters.fileType, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the framework codes which has questionnaires.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     list[str]: framework codes.
         * @summary Get Frameworks Questionnaires
         * @param {ProjectsApiGetFrameworksQuestionnairesApiProjectsProjectIdFrameworksQuestionnairesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFrameworksQuestionnairesApiProjectsProjectIdFrameworksQuestionnairesGet(requestParameters: ProjectsApiGetFrameworksQuestionnairesApiProjectsProjectIdFrameworksQuestionnairesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<string | null>> {
            return localVarFp.getFrameworksQuestionnairesApiProjectsProjectIdFrameworksQuestionnairesGet(requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the logs for the given project and object id.  Args:     project_id (UUID): The project ID.     object_id (UUID): The object ID.  Returns:     List[Logs]: The logs.
         * @summary Get Logs
         * @param {ProjectsApiGetLogsApiProjectsProjectIdLogsObjectIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogsApiProjectsProjectIdLogsObjectIdGet(requestParameters: ProjectsApiGetLogsApiProjectsProjectIdLogsObjectIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Logs>> {
            return localVarFp.getLogsApiProjectsProjectIdLogsObjectIdGet(requestParameters.objectId, requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the project with the given id. Args:     project_id (UUID): The project ID.  Returns:     Project: The project.
         * @summary Get Project
         * @param {ProjectsApiGetProjectApiProjectsProjectIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectApiProjectsProjectIdGet(requestParameters: ProjectsApiGetProjectApiProjectsProjectIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProjectAggregator> {
            return localVarFp.getProjectApiProjectsProjectIdGet(requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the asset_cards of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     Page[schemas.AssetCardPage]: List of asset_cards.
         * @summary Get Project Asset Cards
         * @param {ProjectsApiGetProjectAssetCardsApiProjectsProjectIdAssetCardsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectAssetCardsApiProjectsProjectIdAssetCardsGet(requestParameters: ProjectsApiGetProjectAssetCardsApiProjectsProjectIdAssetCardsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetCardPage> {
            return localVarFp.getProjectAssetCardsApiProjectsProjectIdAssetCardsGet(requestParameters.projectId, requestParameters.name, requestParameters.status, requestParameters.isAssignedToMe, requestParameters.assetCardType, requestParameters.assetCardNature, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the controls of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     Page[schemas.ControlWithoutComponents]: List of controls.
         * @summary Get Project Controls
         * @param {ProjectsApiGetProjectControlsApiProjectsProjectIdControlsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectControlsApiProjectsProjectIdControlsGet(requestParameters: ProjectsApiGetProjectControlsApiProjectsProjectIdControlsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PageControlList> {
            return localVarFp.getProjectControlsApiProjectsProjectIdControlsGet(requestParameters.projectId, requestParameters.tags, requestParameters.name, requestParameters.framework, requestParameters.requirement, requestParameters.status, requestParameters.isAssignedToMe, requestParameters.isAssignedToMeForReview, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Project Controls
         * @param {ProjectsApiGetProjectControlsApiV1ProjectsProjectIdControlsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectControlsApiV1ProjectsProjectIdControlsGet(requestParameters: ProjectsApiGetProjectControlsApiV1ProjectsProjectIdControlsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PageControl> {
            return localVarFp.getProjectControlsApiV1ProjectsProjectIdControlsGet(requestParameters.projectId, requestParameters.status, requestParameters.name, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the controls of the project with the given id which have at least one evidence.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     List[schemas.Risk]: List of risks.
         * @summary Get Project Controls With Evidences
         * @param {ProjectsApiGetProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGet(requestParameters: ProjectsApiGetProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<ControlDisplay>> {
            return localVarFp.getProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGet(requestParameters.projectId, requestParameters.name, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the project dashboard for the given project id.  Args:     project_id (UUID): The project ID.  Returns:     Project: The project dashboard.
         * @summary Get Project Dashboard
         * @param {ProjectsApiGetProjectDashboardApiProjectsProjectIdDashboardGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectDashboardApiProjectsProjectIdDashboardGet(requestParameters: ProjectsApiGetProjectDashboardApiProjectsProjectIdDashboardGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProjectDashboard> {
            return localVarFp.getProjectDashboardApiProjectsProjectIdDashboardGet(requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the frameworks of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     name (str): Filter by framework name.     params (Params): Pagination params.  Returns:     Page[schemas.FrameworkAggregator]: List of frameworks.
         * @summary Get Project Frameworks
         * @param {ProjectsApiGetProjectFrameworksApiProjectsProjectIdFrameworksGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectFrameworksApiProjectsProjectIdFrameworksGet(requestParameters: ProjectsApiGetProjectFrameworksApiProjectsProjectIdFrameworksGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PageFrameworkAggregator> {
            return localVarFp.getProjectFrameworksApiProjectsProjectIdFrameworksGet(requestParameters.projectId, requestParameters.name, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the project pdf for the given project id.  Args:     project_id (UUID): The project ID.  Returns:     FileResponse: The project pdf.
         * @summary Get Project Pdf
         * @param {ProjectsApiGetProjectPdfApiProjectsProjectIdExportGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectPdfApiProjectsProjectIdExportGet(requestParameters: ProjectsApiGetProjectPdfApiProjectsProjectIdExportGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getProjectPdfApiProjectsProjectIdExportGet(requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the requirements of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     name (str): Filter by requirement name.     framework (str): Filter by framework code.     status (str): Filter by requirement status.     params (Params): Pagination params.  Returns:     Page[schemas.Requirement]: Paginated List of requirements.
         * @summary Get Project Requirements
         * @param {ProjectsApiGetProjectRequirementsApiProjectsProjectIdRequirementsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectRequirementsApiProjectsProjectIdRequirementsGet(requestParameters: ProjectsApiGetProjectRequirementsApiProjectsProjectIdRequirementsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PageRequirementAggregator> {
            return localVarFp.getProjectRequirementsApiProjectsProjectIdRequirementsGet(requestParameters.projectId, requestParameters.name, requestParameters.framework, requestParameters.status, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the risks of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     Page[schemas.RiskWithoutComponentsAggregator]: List of risks.
         * @summary Get Project Risks
         * @param {ProjectsApiGetProjectRisksApiProjectsProjectIdRisksGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectRisksApiProjectsProjectIdRisksGet(requestParameters: ProjectsApiGetProjectRisksApiProjectsProjectIdRisksGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PageRisk> {
            return localVarFp.getProjectRisksApiProjectsProjectIdRisksGet(requestParameters.projectId, requestParameters.name, requestParameters.status, requestParameters.isAssignedToMe, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the risks of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     Page[schemas.RiskWithoutComponentsAggregator]: List of risks.
         * @summary Get Project Risks List
         * @param {ProjectsApiGetProjectRisksListApiProjectsProjectIdRisksListGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectRisksListApiProjectsProjectIdRisksListGet(requestParameters: ProjectsApiGetProjectRisksListApiProjectsProjectIdRisksListGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<RiskWithOverviewPage> {
            return localVarFp.getProjectRisksListApiProjectsProjectIdRisksListGet(requestParameters.projectId, requestParameters.name, requestParameters.status, requestParameters.isAssignedToMe, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the risks of the project with the given id which have at least one evidence.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     List[schemas.Risk]: List of risks.
         * @summary Get Project Risks With Evidences
         * @param {ProjectsApiGetProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGet(requestParameters: ProjectsApiGetProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<RiskDisplay>> {
            return localVarFp.getProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGet(requestParameters.projectId, requestParameters.name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Reviews For Concept Type
         * @param {ProjectsApiGetReviewsForConceptTypeApiProjectsProjectIdReviewsConceptGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewsForConceptTypeApiProjectsProjectIdReviewsConceptGet(requestParameters: ProjectsApiGetReviewsForConceptTypeApiProjectsProjectIdReviewsConceptGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Review>> {
            return localVarFp.getReviewsForConceptTypeApiProjectsProjectIdReviewsConceptGet(requestParameters.concept, requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Reviews For Object Id
         * @param {ProjectsApiGetReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGet(requestParameters: ProjectsApiGetReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Review>> {
            return localVarFp.getReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGet(requestParameters.objectId, requestParameters.concept, requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Testing Metric
         * @param {ProjectsApiGetTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet(requestParameters: ProjectsApiGetTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TestingMetric> {
            return localVarFp.getTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet(requestParameters.testingSourceId, requestParameters.testingMetricId, requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Testing Source
         * @param {ProjectsApiGetTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet(requestParameters: ProjectsApiGetTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TestingSource> {
            return localVarFp.getTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet(requestParameters.testingSourceId, requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Testing Source Metrics
         * @param {ProjectsApiGetTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet(requestParameters: ProjectsApiGetTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PageTestingMetric> {
            return localVarFp.getTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet(requestParameters.testingSourceId, requestParameters.projectId, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Testing Sources
         * @param {ProjectsApiGetTestingSourcesApiV1ProjectsProjectIdTestingSourcesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet(requestParameters: ProjectsApiGetTestingSourcesApiV1ProjectsProjectIdTestingSourcesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PageTestingSource> {
            return localVarFp.getTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet(requestParameters.projectId, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Testing Test And Latest Result
         * @param {ProjectsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet(requestParameters: ProjectsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TestingTestAndResultPage> {
            return localVarFp.getTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet(requestParameters.projectId, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, requestParameters.name, requestParameters.resultStatus, requestParameters.status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Testing Test
         * @param {ProjectsApiGetTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet(requestParameters: ProjectsApiGetTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TestingTest> {
            return localVarFp.getTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet(requestParameters.projectId, requestParameters.testingTestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Testing Test Result
         * @param {ProjectsApiGetTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet(requestParameters: ProjectsApiGetTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TestingTestResult> {
            return localVarFp.getTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet(requestParameters.testingTestResultId, requestParameters.projectId, requestParameters.testingTestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Testing Test Results
         * @param {ProjectsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet(requestParameters: ProjectsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TestingTestResultPage> {
            return localVarFp.getTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet(requestParameters.projectId, requestParameters.testingTestId, requestParameters.resultStatus, requestParameters.page, requestParameters.size, requestParameters.sortBy, requestParameters.descending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Testing Tests
         * @param {ProjectsApiGetTestingTestsApiV1ProjectsProjectIdTestingTestsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingTestsApiV1ProjectsProjectIdTestingTestsGet(requestParameters: ProjectsApiGetTestingTestsApiV1ProjectsProjectIdTestingTestsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PageTestingTest> {
            return localVarFp.getTestingTestsApiV1ProjectsProjectIdTestingTestsGet(requestParameters.projectId, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the users of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     params (Params): Pagination params.     roles (List[enums.Roles]): Filter by role.     exclude_me (bool): Include current user.     sort_by (str): Sort by a field.     descending (bool): Sort order. If true, descending, otherwise ascending.  Returns:     Page[schemas.OrganizationUser]: List of users.
         * @summary Get User Of Project
         * @param {ProjectsApiGetUserOfProjectApiProjectsProjectIdUsersGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserOfProjectApiProjectsProjectIdUsersGet(requestParameters: ProjectsApiGetUserOfProjectApiProjectsProjectIdUsersGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserProjectPage> {
            return localVarFp.getUserOfProjectApiProjectsProjectIdUsersGet(requestParameters.projectId, requestParameters.roles, requestParameters.excludeMe, requestParameters.sortBy, requestParameters.descending, requestParameters.name, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove Control From Testing Test
         * @param {ProjectsApiRemoveControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete(requestParameters: ProjectsApiRemoveControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.removeControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete(requestParameters.projectId, requestParameters.testingTestId, requestParameters.controlId, options).then((request) => request(axios, basePath));
        },
        /**
         * Request access to the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     user (User): The user requesting access to the project.
         * @summary Request Access
         * @param {ProjectsApiRequestAccessApiProjectsProjectIdRequestAccessPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestAccessApiProjectsProjectIdRequestAccessPost(requestParameters: ProjectsApiRequestAccessApiProjectsProjectIdRequestAccessPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.requestAccessApiProjectsProjectIdRequestAccessPost(requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Request reviews for a concept object.  Args:     review_request (schemas.ReviewRequest): The review request.     concept (enums.Concept): The concept of the object.     concept_object (schemas.ControlAggregator | schemas.Risk | schemas.AssetCard, optional): The object to request reviews for. # noqa     db (Session, optional): The database session.     project (schemas.Project, optional): The project.     current_user (schemas.OrganizationUser, optional): The current user.     force_update_status (bool, optional): Project owners can force update the         status of the object.  Returns:     List[schemas.Review]: The created reviews.
         * @summary Request Reviews
         * @param {ProjectsApiRequestReviewsApiProjectsProjectIdReviewsConceptObjectIdPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestReviewsApiProjectsProjectIdReviewsConceptObjectIdPost(requestParameters: ProjectsApiRequestReviewsApiProjectsProjectIdReviewsConceptObjectIdPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Review>> {
            return localVarFp.requestReviewsApiProjectsProjectIdReviewsConceptObjectIdPost(requestParameters.concept, requestParameters.objectId, requestParameters.projectId, requestParameters.reviewRequest, requestParameters.forceUpdateStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Run Testing Test
         * @param {ProjectsApiRunTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost(requestParameters: ProjectsApiRunTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TestingTestResult> {
            return localVarFp.runTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost(requestParameters.projectId, requestParameters.testingTestId, options).then((request) => request(axios, basePath));
        },
        /**
         * Take a decision on a review for a concept object.  Args:     project_id (uuid.UUID): The project ID.     concept (enums.Concept): The concept of the object.     review_decision (schemas.ReviewDecision): The review decision.     concept_object (schemas.ControlAggregator | schemas.Risk | schemas.AssetCard, optional): The object. # noqa     db (Session, optional): The database session.     current_user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Review: The updated review.
         * @summary Take Review Decision
         * @param {ProjectsApiTakeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        takeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatch(requestParameters: ProjectsApiTakeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<Review> {
            return localVarFp.takeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatch(requestParameters.projectId, requestParameters.concept, requestParameters.objectId, requestParameters.reviewDecision, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the project with the given id.  Args:     project_id (UUID): The project\'s id.     project_update (UpdateProject): The update to the project.     db (Session): Database session.  Returns:     schemas.Project: The updated project.
         * @summary Update Project
         * @param {ProjectsApiUpdateProjectApiProjectsProjectIdPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectApiProjectsProjectIdPatch(requestParameters: ProjectsApiUpdateProjectApiProjectsProjectIdPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProjectAggregator> {
            return localVarFp.updateProjectApiProjectsProjectIdPatch(requestParameters.projectId, requestParameters.updateProject, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the reviewers for a concept object.  Args:     review_request (schemas.UpdateReviewRequest): The update review request.     concept (enums.Concept): The concept of the object.     concept_object (schemas.ControlAggregator | schemas.Risk | schemas.AssetCard, optional): The object. # noqa     db (Session, optional): The database session.     project (schemas.Project, optional): The project.     current_user (schemas.OrganizationUser, optional): The current user.  Returns:     List[schemas.Review]: The updated reviews.
         * @summary Update Reviewers
         * @param {ProjectsApiUpdateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatch(requestParameters: ProjectsApiUpdateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Review>> {
            return localVarFp.updateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatch(requestParameters.concept, requestParameters.objectId, requestParameters.projectId, requestParameters.updateReviewRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Testing Test
         * @param {ProjectsApiUpdateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch(requestParameters: ProjectsApiUpdateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<TestingTest> {
            return localVarFp.updateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch(requestParameters.projectId, requestParameters.testingTestId, requestParameters.updateTestingTest, options).then((request) => request(axios, basePath));
        },
        /**
         * Uploads the evidence file and creates an entry in the evidence database.  Args:     db (Session): Database session.     project_id (UUID): The project\'s id.     user (User): The user creating the evidence.     file (UploadFile): The file to upload.     component (Component): The component the evidence belongs to.  Returns:     schemas.Evidence: The created evidence.
         * @summary Upload And Create Evidence
         * @param {ProjectsApiUploadAndCreateEvidenceApiProjectsProjectIdEvidencePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAndCreateEvidenceApiProjectsProjectIdEvidencePost(requestParameters: ProjectsApiUploadAndCreateEvidenceApiProjectsProjectIdEvidencePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<Evidence> {
            return localVarFp.uploadAndCreateEvidenceApiProjectsProjectIdEvidencePost(requestParameters.projectId, requestParameters.file, requestParameters.name, requestParameters.description, requestParameters.componentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut operation in ProjectsApi.
 * @export
 * @interface ProjectsApiAddTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPutRequest
 */
export interface ProjectsApiAddTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiAddTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiAddTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut
     */
    readonly controlId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiAddTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut
     */
    readonly testingTestId: string
}

/**
 * Request parameters for cancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDelete operation in ProjectsApi.
 * @export
 * @interface ProjectsApiCancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDeleteRequest
 */
export interface ProjectsApiCancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDeleteRequest {
    /**
     * 
     * @type {Concept}
     * @memberof ProjectsApiCancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDelete
     */
    readonly concept: Concept

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiCancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDelete
     */
    readonly objectId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiCancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDelete
     */
    readonly projectId: string
}

/**
 * Request parameters for createAssetCardApiProjectsProjectIdAssetCardsPost operation in ProjectsApi.
 * @export
 * @interface ProjectsApiCreateAssetCardApiProjectsProjectIdAssetCardsPostRequest
 */
export interface ProjectsApiCreateAssetCardApiProjectsProjectIdAssetCardsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiCreateAssetCardApiProjectsProjectIdAssetCardsPost
     */
    readonly projectId: string

    /**
     * 
     * @type {CreateAssetCard}
     * @memberof ProjectsApiCreateAssetCardApiProjectsProjectIdAssetCardsPost
     */
    readonly createAssetCard: CreateAssetCard
}

/**
 * Request parameters for createCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPost operation in ProjectsApi.
 * @export
 * @interface ProjectsApiCreateCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPostRequest
 */
export interface ProjectsApiCreateCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiCreateCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPost
     */
    readonly objectId: string

    /**
     * 
     * @type {Concept}
     * @memberof ProjectsApiCreateCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPost
     */
    readonly objectType: Concept

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiCreateCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPost
     */
    readonly projectId: string

    /**
     * 
     * @type {CreateComment}
     * @memberof ProjectsApiCreateCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPost
     */
    readonly createComment: CreateComment
}

/**
 * Request parameters for createEuAiActProjectSettingsApiProjectsProjectIdEuAiActSettingsPost operation in ProjectsApi.
 * @export
 * @interface ProjectsApiCreateEuAiActProjectSettingsApiProjectsProjectIdEuAiActSettingsPostRequest
 */
export interface ProjectsApiCreateEuAiActProjectSettingsApiProjectsProjectIdEuAiActSettingsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiCreateEuAiActProjectSettingsApiProjectsProjectIdEuAiActSettingsPost
     */
    readonly projectId: string

    /**
     * 
     * @type {EUAIActProjectSettingsCreate}
     * @memberof ProjectsApiCreateEuAiActProjectSettingsApiProjectsProjectIdEuAiActSettingsPost
     */
    readonly eUAIActProjectSettingsCreate: EUAIActProjectSettingsCreate
}

/**
 * Request parameters for createProjectApiProjectsPost operation in ProjectsApi.
 * @export
 * @interface ProjectsApiCreateProjectApiProjectsPostRequest
 */
export interface ProjectsApiCreateProjectApiProjectsPostRequest {
    /**
     * 
     * @type {CreateProject}
     * @memberof ProjectsApiCreateProjectApiProjectsPost
     */
    readonly createProject: CreateProject
}

/**
 * Request parameters for createProjectFrameworkApiProjectsProjectIdFrameworksPost operation in ProjectsApi.
 * @export
 * @interface ProjectsApiCreateProjectFrameworkApiProjectsProjectIdFrameworksPostRequest
 */
export interface ProjectsApiCreateProjectFrameworkApiProjectsProjectIdFrameworksPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiCreateProjectFrameworkApiProjectsProjectIdFrameworksPost
     */
    readonly projectId: string

    /**
     * 
     * @type {Array<string | null>}
     * @memberof ProjectsApiCreateProjectFrameworkApiProjectsProjectIdFrameworksPost
     */
    readonly requestBody: Array<string | null>
}

/**
 * Request parameters for createRiskApiProjectsProjectIdRisksPost operation in ProjectsApi.
 * @export
 * @interface ProjectsApiCreateRiskApiProjectsProjectIdRisksPostRequest
 */
export interface ProjectsApiCreateRiskApiProjectsProjectIdRisksPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiCreateRiskApiProjectsProjectIdRisksPost
     */
    readonly projectId: string

    /**
     * 
     * @type {CreateRisk}
     * @memberof ProjectsApiCreateRiskApiProjectsProjectIdRisksPost
     */
    readonly createRisk: CreateRisk
}

/**
 * Request parameters for createTestingLogApiV1ProjectsProjectIdTestingLogsPost operation in ProjectsApi.
 * @export
 * @interface ProjectsApiCreateTestingLogApiV1ProjectsProjectIdTestingLogsPostRequest
 */
export interface ProjectsApiCreateTestingLogApiV1ProjectsProjectIdTestingLogsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiCreateTestingLogApiV1ProjectsProjectIdTestingLogsPost
     */
    readonly projectId: string

    /**
     * 
     * @type {TestingLogCreate}
     * @memberof ProjectsApiCreateTestingLogApiV1ProjectsProjectIdTestingLogsPost
     */
    readonly testingLogCreate: TestingLogCreate
}

/**
 * Request parameters for createTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost operation in ProjectsApi.
 * @export
 * @interface ProjectsApiCreateTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPostRequest
 */
export interface ProjectsApiCreateTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiCreateTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost
     */
    readonly testingSourceId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiCreateTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost
     */
    readonly projectId: string

    /**
     * 
     * @type {TestingMetricCreate}
     * @memberof ProjectsApiCreateTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost
     */
    readonly testingMetricCreate: TestingMetricCreate
}

/**
 * Request parameters for createTestingTestApiV1ProjectsProjectIdTestingTestsPost operation in ProjectsApi.
 * @export
 * @interface ProjectsApiCreateTestingTestApiV1ProjectsProjectIdTestingTestsPostRequest
 */
export interface ProjectsApiCreateTestingTestApiV1ProjectsProjectIdTestingTestsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiCreateTestingTestApiV1ProjectsProjectIdTestingTestsPost
     */
    readonly projectId: string

    /**
     * 
     * @type {CreateTestingTest}
     * @memberof ProjectsApiCreateTestingTestApiV1ProjectsProjectIdTestingTestsPost
     */
    readonly createTestingTest: CreateTestingTest
}

/**
 * Request parameters for deleteProjectApiProjectsProjectIdDelete operation in ProjectsApi.
 * @export
 * @interface ProjectsApiDeleteProjectApiProjectsProjectIdDeleteRequest
 */
export interface ProjectsApiDeleteProjectApiProjectsProjectIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiDeleteProjectApiProjectsProjectIdDelete
     */
    readonly projectId: string
}

/**
 * Request parameters for getAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGetRequest
 */
export interface ProjectsApiGetAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGet
     */
    readonly projectId: string

    /**
     * 
     * @type {AiLifecycleDashboardContent}
     * @memberof ProjectsApiGetAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGet
     */
    readonly dashboardContent?: AiLifecycleDashboardContent
}

/**
 * Request parameters for getCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGetRequest
 */
export interface ProjectsApiGetCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGet
     */
    readonly objectId: string

    /**
     * 
     * @type {Concept}
     * @memberof ProjectsApiGetCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGet
     */
    readonly objectType: Concept

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGet
     */
    readonly projectId: string
}

/**
 * Request parameters for getControlByCodeApiProjectsProjectIdControlsControlCodeGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetControlByCodeApiProjectsProjectIdControlsControlCodeGetRequest
 */
export interface ProjectsApiGetControlByCodeApiProjectsProjectIdControlsControlCodeGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetControlByCodeApiProjectsProjectIdControlsControlCodeGet
     */
    readonly controlCode: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetControlByCodeApiProjectsProjectIdControlsControlCodeGet
     */
    readonly projectId: string
}

/**
 * Request parameters for getControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGetRequest
 */
export interface ProjectsApiGetControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet
     */
    readonly testingTestId: string
}

/**
 * Request parameters for getEuAiActProjectSettingsApiProjectsProjectIdEuAiActSettingsGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetEuAiActProjectSettingsApiProjectsProjectIdEuAiActSettingsGetRequest
 */
export interface ProjectsApiGetEuAiActProjectSettingsApiProjectsProjectIdEuAiActSettingsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetEuAiActProjectSettingsApiProjectsProjectIdEuAiActSettingsGet
     */
    readonly projectId: string
}

/**
 * Request parameters for getEvidenceFileTypesApiProjectsProjectIdEvidenceFilesTypesGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetEvidenceFileTypesApiProjectsProjectIdEvidenceFilesTypesGetRequest
 */
export interface ProjectsApiGetEvidenceFileTypesApiProjectsProjectIdEvidenceFilesTypesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetEvidenceFileTypesApiProjectsProjectIdEvidenceFilesTypesGet
     */
    readonly projectId: string
}

/**
 * Request parameters for getEvidenceListApiProjectsProjectIdEvidenceListGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetEvidenceListApiProjectsProjectIdEvidenceListGetRequest
 */
export interface ProjectsApiGetEvidenceListApiProjectsProjectIdEvidenceListGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetEvidenceListApiProjectsProjectIdEvidenceListGet
     */
    readonly projectId: string

    /**
     * Filter by evidence name
     * @type {string}
     * @memberof ProjectsApiGetEvidenceListApiProjectsProjectIdEvidenceListGet
     */
    readonly name?: string | null
}

/**
 * Request parameters for getEvidencesByProjectIdApiProjectsProjectIdEvidenceGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetEvidencesByProjectIdApiProjectsProjectIdEvidenceGetRequest
 */
export interface ProjectsApiGetEvidencesByProjectIdApiProjectsProjectIdEvidenceGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetEvidencesByProjectIdApiProjectsProjectIdEvidenceGet
     */
    readonly projectId: string

    /**
     * Filter by evidence name
     * @type {string}
     * @memberof ProjectsApiGetEvidencesByProjectIdApiProjectsProjectIdEvidenceGet
     */
    readonly name?: string | null

    /**
     * Sort by a field.
     * @type {string}
     * @memberof ProjectsApiGetEvidencesByProjectIdApiProjectsProjectIdEvidenceGet
     */
    readonly sortBy?: string | null

    /**
     * Sort order. If true, descending, otherwise ascending.
     * @type {boolean}
     * @memberof ProjectsApiGetEvidencesByProjectIdApiProjectsProjectIdEvidenceGet
     */
    readonly descending?: boolean

    /**
     * Filter by associated controls
     * @type {Array<string>}
     * @memberof ProjectsApiGetEvidencesByProjectIdApiProjectsProjectIdEvidenceGet
     */
    readonly associatedControls?: Array<string>

    /**
     * Filter by associated risks
     * @type {Array<string>}
     * @memberof ProjectsApiGetEvidencesByProjectIdApiProjectsProjectIdEvidenceGet
     */
    readonly associatedRisks?: Array<string>

    /**
     * Filter by creation date of the evidence
     * @type {string}
     * @memberof ProjectsApiGetEvidencesByProjectIdApiProjectsProjectIdEvidenceGet
     */
    readonly startDate?: string | null

    /**
     * Filter by creation date of the evidence
     * @type {string}
     * @memberof ProjectsApiGetEvidencesByProjectIdApiProjectsProjectIdEvidenceGet
     */
    readonly endDate?: string | null

    /**
     * Filter by file type (e.g. \&#39;Image\&#39;)
     * @type {string}
     * @memberof ProjectsApiGetEvidencesByProjectIdApiProjectsProjectIdEvidenceGet
     */
    readonly fileType?: string | null

    /**
     * Page number
     * @type {number}
     * @memberof ProjectsApiGetEvidencesByProjectIdApiProjectsProjectIdEvidenceGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof ProjectsApiGetEvidencesByProjectIdApiProjectsProjectIdEvidenceGet
     */
    readonly size?: number
}

/**
 * Request parameters for getFrameworksQuestionnairesApiProjectsProjectIdFrameworksQuestionnairesGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetFrameworksQuestionnairesApiProjectsProjectIdFrameworksQuestionnairesGetRequest
 */
export interface ProjectsApiGetFrameworksQuestionnairesApiProjectsProjectIdFrameworksQuestionnairesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetFrameworksQuestionnairesApiProjectsProjectIdFrameworksQuestionnairesGet
     */
    readonly projectId: string
}

/**
 * Request parameters for getLogsApiProjectsProjectIdLogsObjectIdGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetLogsApiProjectsProjectIdLogsObjectIdGetRequest
 */
export interface ProjectsApiGetLogsApiProjectsProjectIdLogsObjectIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetLogsApiProjectsProjectIdLogsObjectIdGet
     */
    readonly objectId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetLogsApiProjectsProjectIdLogsObjectIdGet
     */
    readonly projectId: string
}

/**
 * Request parameters for getProjectApiProjectsProjectIdGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetProjectApiProjectsProjectIdGetRequest
 */
export interface ProjectsApiGetProjectApiProjectsProjectIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetProjectApiProjectsProjectIdGet
     */
    readonly projectId: string
}

/**
 * Request parameters for getProjectAssetCardsApiProjectsProjectIdAssetCardsGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetProjectAssetCardsApiProjectsProjectIdAssetCardsGetRequest
 */
export interface ProjectsApiGetProjectAssetCardsApiProjectsProjectIdAssetCardsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetProjectAssetCardsApiProjectsProjectIdAssetCardsGet
     */
    readonly projectId: string

    /**
     * Filter by asset_card name
     * @type {string}
     * @memberof ProjectsApiGetProjectAssetCardsApiProjectsProjectIdAssetCardsGet
     */
    readonly name?: string | null

    /**
     * Filter by asset_card status
     * @type {string}
     * @memberof ProjectsApiGetProjectAssetCardsApiProjectsProjectIdAssetCardsGet
     */
    readonly status?: string | null

    /**
     * Filter by asset_cards assigned to me
     * @type {boolean}
     * @memberof ProjectsApiGetProjectAssetCardsApiProjectsProjectIdAssetCardsGet
     */
    readonly isAssignedToMe?: boolean

    /**
     * Filter by asset_card_type
     * @type {AssetCardTypes}
     * @memberof ProjectsApiGetProjectAssetCardsApiProjectsProjectIdAssetCardsGet
     */
    readonly assetCardType?: AssetCardTypes | null

    /**
     * Filter by asset card nature
     * @type {AssetCardNature}
     * @memberof ProjectsApiGetProjectAssetCardsApiProjectsProjectIdAssetCardsGet
     */
    readonly assetCardNature?: AssetCardNature | null

    /**
     * Sort by a field.
     * @type {string}
     * @memberof ProjectsApiGetProjectAssetCardsApiProjectsProjectIdAssetCardsGet
     */
    readonly sortBy?: string | null

    /**
     * Sort order. If true, descending, otherwise ascending.
     * @type {boolean}
     * @memberof ProjectsApiGetProjectAssetCardsApiProjectsProjectIdAssetCardsGet
     */
    readonly descending?: boolean

    /**
     * Page number
     * @type {number}
     * @memberof ProjectsApiGetProjectAssetCardsApiProjectsProjectIdAssetCardsGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof ProjectsApiGetProjectAssetCardsApiProjectsProjectIdAssetCardsGet
     */
    readonly size?: number
}

/**
 * Request parameters for getProjectControlsApiProjectsProjectIdControlsGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetProjectControlsApiProjectsProjectIdControlsGetRequest
 */
export interface ProjectsApiGetProjectControlsApiProjectsProjectIdControlsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetProjectControlsApiProjectsProjectIdControlsGet
     */
    readonly projectId: string

    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectsApiGetProjectControlsApiProjectsProjectIdControlsGet
     */
    readonly tags?: Array<string>

    /**
     * Filter by control name
     * @type {string}
     * @memberof ProjectsApiGetProjectControlsApiProjectsProjectIdControlsGet
     */
    readonly name?: string | null

    /**
     * Filter by framework code
     * @type {string}
     * @memberof ProjectsApiGetProjectControlsApiProjectsProjectIdControlsGet
     */
    readonly framework?: string | null

    /**
     * Filter by requirement id
     * @type {string}
     * @memberof ProjectsApiGetProjectControlsApiProjectsProjectIdControlsGet
     */
    readonly requirement?: string | null

    /**
     * Filter by control status
     * @type {string}
     * @memberof ProjectsApiGetProjectControlsApiProjectsProjectIdControlsGet
     */
    readonly status?: string | null

    /**
     * Filter by controls assigned to me
     * @type {boolean}
     * @memberof ProjectsApiGetProjectControlsApiProjectsProjectIdControlsGet
     */
    readonly isAssignedToMe?: boolean

    /**
     * Filter by controls assigned to me for review
     * @type {boolean}
     * @memberof ProjectsApiGetProjectControlsApiProjectsProjectIdControlsGet
     */
    readonly isAssignedToMeForReview?: boolean

    /**
     * Page number
     * @type {number}
     * @memberof ProjectsApiGetProjectControlsApiProjectsProjectIdControlsGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof ProjectsApiGetProjectControlsApiProjectsProjectIdControlsGet
     */
    readonly size?: number
}

/**
 * Request parameters for getProjectControlsApiV1ProjectsProjectIdControlsGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetProjectControlsApiV1ProjectsProjectIdControlsGetRequest
 */
export interface ProjectsApiGetProjectControlsApiV1ProjectsProjectIdControlsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetProjectControlsApiV1ProjectsProjectIdControlsGet
     */
    readonly projectId: string

    /**
     * Filter by control status
     * @type {Status}
     * @memberof ProjectsApiGetProjectControlsApiV1ProjectsProjectIdControlsGet
     */
    readonly status?: Status | null

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetProjectControlsApiV1ProjectsProjectIdControlsGet
     */
    readonly name?: string | null

    /**
     * Page number
     * @type {number}
     * @memberof ProjectsApiGetProjectControlsApiV1ProjectsProjectIdControlsGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof ProjectsApiGetProjectControlsApiV1ProjectsProjectIdControlsGet
     */
    readonly size?: number
}

/**
 * Request parameters for getProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGetRequest
 */
export interface ProjectsApiGetProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGet
     */
    readonly projectId: string

    /**
     * Filter by control name
     * @type {string}
     * @memberof ProjectsApiGetProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGet
     */
    readonly name?: string | null
}

/**
 * Request parameters for getProjectDashboardApiProjectsProjectIdDashboardGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetProjectDashboardApiProjectsProjectIdDashboardGetRequest
 */
export interface ProjectsApiGetProjectDashboardApiProjectsProjectIdDashboardGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetProjectDashboardApiProjectsProjectIdDashboardGet
     */
    readonly projectId: string
}

/**
 * Request parameters for getProjectFrameworksApiProjectsProjectIdFrameworksGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetProjectFrameworksApiProjectsProjectIdFrameworksGetRequest
 */
export interface ProjectsApiGetProjectFrameworksApiProjectsProjectIdFrameworksGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetProjectFrameworksApiProjectsProjectIdFrameworksGet
     */
    readonly projectId: string

    /**
     * Filter by framework name
     * @type {string}
     * @memberof ProjectsApiGetProjectFrameworksApiProjectsProjectIdFrameworksGet
     */
    readonly name?: string | null

    /**
     * Sort by a field.
     * @type {string}
     * @memberof ProjectsApiGetProjectFrameworksApiProjectsProjectIdFrameworksGet
     */
    readonly sortBy?: string | null

    /**
     * Sort order. If true, descending, otherwise ascending.
     * @type {boolean}
     * @memberof ProjectsApiGetProjectFrameworksApiProjectsProjectIdFrameworksGet
     */
    readonly descending?: boolean

    /**
     * Page number
     * @type {number}
     * @memberof ProjectsApiGetProjectFrameworksApiProjectsProjectIdFrameworksGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof ProjectsApiGetProjectFrameworksApiProjectsProjectIdFrameworksGet
     */
    readonly size?: number
}

/**
 * Request parameters for getProjectPdfApiProjectsProjectIdExportGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetProjectPdfApiProjectsProjectIdExportGetRequest
 */
export interface ProjectsApiGetProjectPdfApiProjectsProjectIdExportGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetProjectPdfApiProjectsProjectIdExportGet
     */
    readonly projectId: string
}

/**
 * Request parameters for getProjectRequirementsApiProjectsProjectIdRequirementsGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetProjectRequirementsApiProjectsProjectIdRequirementsGetRequest
 */
export interface ProjectsApiGetProjectRequirementsApiProjectsProjectIdRequirementsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetProjectRequirementsApiProjectsProjectIdRequirementsGet
     */
    readonly projectId: string

    /**
     * Filter by requirement name
     * @type {string}
     * @memberof ProjectsApiGetProjectRequirementsApiProjectsProjectIdRequirementsGet
     */
    readonly name?: string | null

    /**
     * Filter by framework code
     * @type {string}
     * @memberof ProjectsApiGetProjectRequirementsApiProjectsProjectIdRequirementsGet
     */
    readonly framework?: string | null

    /**
     * Filter by requirement status
     * @type {string}
     * @memberof ProjectsApiGetProjectRequirementsApiProjectsProjectIdRequirementsGet
     */
    readonly status?: string | null

    /**
     * Sort by a field.
     * @type {string}
     * @memberof ProjectsApiGetProjectRequirementsApiProjectsProjectIdRequirementsGet
     */
    readonly sortBy?: string | null

    /**
     * Sort order. If true, descending, otherwise ascending.
     * @type {boolean}
     * @memberof ProjectsApiGetProjectRequirementsApiProjectsProjectIdRequirementsGet
     */
    readonly descending?: boolean

    /**
     * Page number
     * @type {number}
     * @memberof ProjectsApiGetProjectRequirementsApiProjectsProjectIdRequirementsGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof ProjectsApiGetProjectRequirementsApiProjectsProjectIdRequirementsGet
     */
    readonly size?: number
}

/**
 * Request parameters for getProjectRisksApiProjectsProjectIdRisksGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetProjectRisksApiProjectsProjectIdRisksGetRequest
 */
export interface ProjectsApiGetProjectRisksApiProjectsProjectIdRisksGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetProjectRisksApiProjectsProjectIdRisksGet
     */
    readonly projectId: string

    /**
     * Filter by risk name
     * @type {string}
     * @memberof ProjectsApiGetProjectRisksApiProjectsProjectIdRisksGet
     */
    readonly name?: string | null

    /**
     * Filter by risk status
     * @type {string}
     * @memberof ProjectsApiGetProjectRisksApiProjectsProjectIdRisksGet
     */
    readonly status?: string | null

    /**
     * Filter by risks assigned to me
     * @type {boolean}
     * @memberof ProjectsApiGetProjectRisksApiProjectsProjectIdRisksGet
     */
    readonly isAssignedToMe?: boolean

    /**
     * Sort by a field.
     * @type {string}
     * @memberof ProjectsApiGetProjectRisksApiProjectsProjectIdRisksGet
     */
    readonly sortBy?: string | null

    /**
     * Sort order. If true, descending, otherwise ascending.
     * @type {boolean}
     * @memberof ProjectsApiGetProjectRisksApiProjectsProjectIdRisksGet
     */
    readonly descending?: boolean

    /**
     * Page number
     * @type {number}
     * @memberof ProjectsApiGetProjectRisksApiProjectsProjectIdRisksGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof ProjectsApiGetProjectRisksApiProjectsProjectIdRisksGet
     */
    readonly size?: number
}

/**
 * Request parameters for getProjectRisksListApiProjectsProjectIdRisksListGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetProjectRisksListApiProjectsProjectIdRisksListGetRequest
 */
export interface ProjectsApiGetProjectRisksListApiProjectsProjectIdRisksListGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetProjectRisksListApiProjectsProjectIdRisksListGet
     */
    readonly projectId: string

    /**
     * Filter by risk name
     * @type {string}
     * @memberof ProjectsApiGetProjectRisksListApiProjectsProjectIdRisksListGet
     */
    readonly name?: string | null

    /**
     * Filter by risk status
     * @type {string}
     * @memberof ProjectsApiGetProjectRisksListApiProjectsProjectIdRisksListGet
     */
    readonly status?: string | null

    /**
     * Filter by risks assigned to me
     * @type {boolean}
     * @memberof ProjectsApiGetProjectRisksListApiProjectsProjectIdRisksListGet
     */
    readonly isAssignedToMe?: boolean

    /**
     * Sort by a field.
     * @type {string}
     * @memberof ProjectsApiGetProjectRisksListApiProjectsProjectIdRisksListGet
     */
    readonly sortBy?: string | null

    /**
     * Sort order. If true, descending, otherwise ascending.
     * @type {boolean}
     * @memberof ProjectsApiGetProjectRisksListApiProjectsProjectIdRisksListGet
     */
    readonly descending?: boolean

    /**
     * Page number
     * @type {number}
     * @memberof ProjectsApiGetProjectRisksListApiProjectsProjectIdRisksListGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof ProjectsApiGetProjectRisksListApiProjectsProjectIdRisksListGet
     */
    readonly size?: number
}

/**
 * Request parameters for getProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGetRequest
 */
export interface ProjectsApiGetProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGet
     */
    readonly projectId: string

    /**
     * Filter by risk name
     * @type {string}
     * @memberof ProjectsApiGetProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGet
     */
    readonly name?: string | null
}

/**
 * Request parameters for getReviewsForConceptTypeApiProjectsProjectIdReviewsConceptGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetReviewsForConceptTypeApiProjectsProjectIdReviewsConceptGetRequest
 */
export interface ProjectsApiGetReviewsForConceptTypeApiProjectsProjectIdReviewsConceptGetRequest {
    /**
     * 
     * @type {Concept}
     * @memberof ProjectsApiGetReviewsForConceptTypeApiProjectsProjectIdReviewsConceptGet
     */
    readonly concept: Concept

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetReviewsForConceptTypeApiProjectsProjectIdReviewsConceptGet
     */
    readonly projectId: string
}

/**
 * Request parameters for getReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGetRequest
 */
export interface ProjectsApiGetReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGet
     */
    readonly objectId: string

    /**
     * 
     * @type {Concept}
     * @memberof ProjectsApiGetReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGet
     */
    readonly concept: Concept

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGet
     */
    readonly projectId: string
}

/**
 * Request parameters for getTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGetRequest
 */
export interface ProjectsApiGetTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet
     */
    readonly testingSourceId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet
     */
    readonly testingMetricId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet
     */
    readonly projectId: string
}

/**
 * Request parameters for getTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGetRequest
 */
export interface ProjectsApiGetTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet
     */
    readonly testingSourceId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet
     */
    readonly projectId: string
}

/**
 * Request parameters for getTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGetRequest
 */
export interface ProjectsApiGetTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet
     */
    readonly testingSourceId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet
     */
    readonly projectId: string

    /**
     * Page number
     * @type {number}
     * @memberof ProjectsApiGetTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof ProjectsApiGetTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet
     */
    readonly size?: number
}

/**
 * Request parameters for getTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetTestingSourcesApiV1ProjectsProjectIdTestingSourcesGetRequest
 */
export interface ProjectsApiGetTestingSourcesApiV1ProjectsProjectIdTestingSourcesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet
     */
    readonly projectId: string

    /**
     * Page number
     * @type {number}
     * @memberof ProjectsApiGetTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof ProjectsApiGetTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet
     */
    readonly size?: number
}

/**
 * Request parameters for getTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGetRequest
 */
export interface ProjectsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet
     */
    readonly projectId: string

    /**
     * Column name to sort by
     * @type {string}
     * @memberof ProjectsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet
     */
    readonly sortBy?: string | null

    /**
     * Sort in descending order
     * @type {boolean}
     * @memberof ProjectsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet
     */
    readonly descending?: boolean

    /**
     * Page number
     * @type {number}
     * @memberof ProjectsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof ProjectsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet
     */
    readonly size?: number

    /**
     * Filter by name
     * @type {string}
     * @memberof ProjectsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet
     */
    readonly name?: string | null

    /**
     * Filter by result status
     * @type {TestingTestStatus}
     * @memberof ProjectsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet
     */
    readonly resultStatus?: TestingTestStatus | null

    /**
     * Filter by test status
     * @type {boolean}
     * @memberof ProjectsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet
     */
    readonly status?: boolean | null
}

/**
 * Request parameters for getTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGetRequest
 */
export interface ProjectsApiGetTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet
     */
    readonly testingTestId: string
}

/**
 * Request parameters for getTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGetRequest
 */
export interface ProjectsApiGetTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet
     */
    readonly testingTestResultId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet
     */
    readonly testingTestId: string
}

/**
 * Request parameters for getTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGetRequest
 */
export interface ProjectsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet
     */
    readonly testingTestId: string

    /**
     * Filter by result status
     * @type {TestingTestStatus}
     * @memberof ProjectsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet
     */
    readonly resultStatus?: TestingTestStatus | null

    /**
     * Page number
     * @type {number}
     * @memberof ProjectsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof ProjectsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet
     */
    readonly size?: number

    /**
     * Column name to sort by
     * @type {string}
     * @memberof ProjectsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet
     */
    readonly sortBy?: string | null

    /**
     * Sort in descending order
     * @type {boolean}
     * @memberof ProjectsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet
     */
    readonly descending?: boolean
}

/**
 * Request parameters for getTestingTestsApiV1ProjectsProjectIdTestingTestsGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetTestingTestsApiV1ProjectsProjectIdTestingTestsGetRequest
 */
export interface ProjectsApiGetTestingTestsApiV1ProjectsProjectIdTestingTestsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetTestingTestsApiV1ProjectsProjectIdTestingTestsGet
     */
    readonly projectId: string

    /**
     * Page number
     * @type {number}
     * @memberof ProjectsApiGetTestingTestsApiV1ProjectsProjectIdTestingTestsGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof ProjectsApiGetTestingTestsApiV1ProjectsProjectIdTestingTestsGet
     */
    readonly size?: number
}

/**
 * Request parameters for getUserOfProjectApiProjectsProjectIdUsersGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetUserOfProjectApiProjectsProjectIdUsersGetRequest
 */
export interface ProjectsApiGetUserOfProjectApiProjectsProjectIdUsersGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetUserOfProjectApiProjectsProjectIdUsersGet
     */
    readonly projectId: string

    /**
     * Filter by role.
     * @type {Array<Roles>}
     * @memberof ProjectsApiGetUserOfProjectApiProjectsProjectIdUsersGet
     */
    readonly roles?: Array<Roles>

    /**
     * Exclude current user.
     * @type {boolean}
     * @memberof ProjectsApiGetUserOfProjectApiProjectsProjectIdUsersGet
     */
    readonly excludeMe?: boolean

    /**
     * Sort by a field.
     * @type {string}
     * @memberof ProjectsApiGetUserOfProjectApiProjectsProjectIdUsersGet
     */
    readonly sortBy?: string | null

    /**
     * Sort order. If true, descending, otherwise ascending.
     * @type {boolean}
     * @memberof ProjectsApiGetUserOfProjectApiProjectsProjectIdUsersGet
     */
    readonly descending?: boolean

    /**
     * The user name filter.
     * @type {string}
     * @memberof ProjectsApiGetUserOfProjectApiProjectsProjectIdUsersGet
     */
    readonly name?: string | null

    /**
     * Page number
     * @type {number}
     * @memberof ProjectsApiGetUserOfProjectApiProjectsProjectIdUsersGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof ProjectsApiGetUserOfProjectApiProjectsProjectIdUsersGet
     */
    readonly size?: number
}

/**
 * Request parameters for removeControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete operation in ProjectsApi.
 * @export
 * @interface ProjectsApiRemoveControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDeleteRequest
 */
export interface ProjectsApiRemoveControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiRemoveControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiRemoveControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete
     */
    readonly testingTestId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiRemoveControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete
     */
    readonly controlId: string
}

/**
 * Request parameters for requestAccessApiProjectsProjectIdRequestAccessPost operation in ProjectsApi.
 * @export
 * @interface ProjectsApiRequestAccessApiProjectsProjectIdRequestAccessPostRequest
 */
export interface ProjectsApiRequestAccessApiProjectsProjectIdRequestAccessPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiRequestAccessApiProjectsProjectIdRequestAccessPost
     */
    readonly projectId: string
}

/**
 * Request parameters for requestReviewsApiProjectsProjectIdReviewsConceptObjectIdPost operation in ProjectsApi.
 * @export
 * @interface ProjectsApiRequestReviewsApiProjectsProjectIdReviewsConceptObjectIdPostRequest
 */
export interface ProjectsApiRequestReviewsApiProjectsProjectIdReviewsConceptObjectIdPostRequest {
    /**
     * 
     * @type {Concept}
     * @memberof ProjectsApiRequestReviewsApiProjectsProjectIdReviewsConceptObjectIdPost
     */
    readonly concept: Concept

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiRequestReviewsApiProjectsProjectIdReviewsConceptObjectIdPost
     */
    readonly objectId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiRequestReviewsApiProjectsProjectIdReviewsConceptObjectIdPost
     */
    readonly projectId: string

    /**
     * 
     * @type {ReviewRequest}
     * @memberof ProjectsApiRequestReviewsApiProjectsProjectIdReviewsConceptObjectIdPost
     */
    readonly reviewRequest: ReviewRequest

    /**
     * 
     * @type {boolean}
     * @memberof ProjectsApiRequestReviewsApiProjectsProjectIdReviewsConceptObjectIdPost
     */
    readonly forceUpdateStatus?: boolean
}

/**
 * Request parameters for runTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost operation in ProjectsApi.
 * @export
 * @interface ProjectsApiRunTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPostRequest
 */
export interface ProjectsApiRunTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiRunTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiRunTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost
     */
    readonly testingTestId: string
}

/**
 * Request parameters for takeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatch operation in ProjectsApi.
 * @export
 * @interface ProjectsApiTakeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatchRequest
 */
export interface ProjectsApiTakeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiTakeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatch
     */
    readonly projectId: string

    /**
     * 
     * @type {Concept}
     * @memberof ProjectsApiTakeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatch
     */
    readonly concept: Concept

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiTakeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatch
     */
    readonly objectId: string

    /**
     * 
     * @type {ReviewDecision}
     * @memberof ProjectsApiTakeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatch
     */
    readonly reviewDecision: ReviewDecision
}

/**
 * Request parameters for updateProjectApiProjectsProjectIdPatch operation in ProjectsApi.
 * @export
 * @interface ProjectsApiUpdateProjectApiProjectsProjectIdPatchRequest
 */
export interface ProjectsApiUpdateProjectApiProjectsProjectIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiUpdateProjectApiProjectsProjectIdPatch
     */
    readonly projectId: string

    /**
     * 
     * @type {UpdateProject}
     * @memberof ProjectsApiUpdateProjectApiProjectsProjectIdPatch
     */
    readonly updateProject: UpdateProject
}

/**
 * Request parameters for updateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatch operation in ProjectsApi.
 * @export
 * @interface ProjectsApiUpdateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatchRequest
 */
export interface ProjectsApiUpdateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatchRequest {
    /**
     * 
     * @type {Concept}
     * @memberof ProjectsApiUpdateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatch
     */
    readonly concept: Concept

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiUpdateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatch
     */
    readonly objectId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiUpdateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatch
     */
    readonly projectId: string

    /**
     * 
     * @type {UpdateReviewRequest}
     * @memberof ProjectsApiUpdateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatch
     */
    readonly updateReviewRequest: UpdateReviewRequest
}

/**
 * Request parameters for updateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch operation in ProjectsApi.
 * @export
 * @interface ProjectsApiUpdateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatchRequest
 */
export interface ProjectsApiUpdateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiUpdateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiUpdateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch
     */
    readonly testingTestId: string

    /**
     * 
     * @type {UpdateTestingTest}
     * @memberof ProjectsApiUpdateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch
     */
    readonly updateTestingTest: UpdateTestingTest
}

/**
 * Request parameters for uploadAndCreateEvidenceApiProjectsProjectIdEvidencePost operation in ProjectsApi.
 * @export
 * @interface ProjectsApiUploadAndCreateEvidenceApiProjectsProjectIdEvidencePostRequest
 */
export interface ProjectsApiUploadAndCreateEvidenceApiProjectsProjectIdEvidencePostRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiUploadAndCreateEvidenceApiProjectsProjectIdEvidencePost
     */
    readonly projectId: string

    /**
     * 
     * @type {File}
     * @memberof ProjectsApiUploadAndCreateEvidenceApiProjectsProjectIdEvidencePost
     */
    readonly file: File

    /**
     * The file name.
     * @type {string}
     * @memberof ProjectsApiUploadAndCreateEvidenceApiProjectsProjectIdEvidencePost
     */
    readonly name?: string

    /**
     * The description.
     * @type {string}
     * @memberof ProjectsApiUploadAndCreateEvidenceApiProjectsProjectIdEvidencePost
     */
    readonly description?: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiUploadAndCreateEvidenceApiProjectsProjectIdEvidencePost
     */
    readonly componentId?: string | null
}

/**
 * ProjectsApi - object-oriented interface
 * @export
 * @class ProjectsApi
 * @extends {BaseAPI}
 */
export class ProjectsApi extends BaseAPI {
    /**
     * 
     * @summary Add Testing Testing To Control
     * @param {ProjectsApiAddTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public addTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut(requestParameters: ProjectsApiAddTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPutRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).addTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut(requestParameters.projectId, requestParameters.controlId, requestParameters.testingTestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Cancel all pending reviews for a concept object.  Args:     concept_object (schemas.ControlAggregator | schemas.Risk | schemas.AssetCard, optional): The object. # noqa     db (Session, optional): The database session.     project (schemas.Project, optional): The project.     current_user (schemas.OrganizationUser, optional): The current user.
     * @summary Cancel Pending Reviews
     * @param {ProjectsApiCancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public cancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDelete(requestParameters: ProjectsApiCancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).cancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDelete(requestParameters.concept, requestParameters.objectId, requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new asset_card.  Args:     asset_card (schemas.CreateAssetCard): The asset_card to create.     response (Response): The response object.     db (Session, optional): The database session.     project (schemas.Project, optional): The project to create the asset_card for.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.AssetCard: The created AssetCard.
     * @summary Create Asset Card
     * @param {ProjectsApiCreateAssetCardApiProjectsProjectIdAssetCardsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public createAssetCardApiProjectsProjectIdAssetCardsPost(requestParameters: ProjectsApiCreateAssetCardApiProjectsProjectIdAssetCardsPostRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).createAssetCardApiProjectsProjectIdAssetCardsPost(requestParameters.projectId, requestParameters.createAssetCard, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Args:     object_type (str): The object type. (enums.Concept)     object_id (UUID): The object ID.     comment (schemas.Comment): The comment to create.     response (Response): The response object.     project (schemas.Project): The project.     db (Session, optional): The database session.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Control: The updated control.
     * @summary Create Comment
     * @param {ProjectsApiCreateCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public createCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPost(requestParameters: ProjectsApiCreateCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPostRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).createCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPost(requestParameters.objectId, requestParameters.objectType, requestParameters.projectId, requestParameters.createComment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the project EU AI Act settings.  Args:     settings (ProjectSettingsCreate): The project settings.     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     schemas.EUAIActProjectSettings: EU AI Act settings.
     * @summary Create Eu Ai Act Project Settings
     * @param {ProjectsApiCreateEuAiActProjectSettingsApiProjectsProjectIdEuAiActSettingsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public createEuAiActProjectSettingsApiProjectsProjectIdEuAiActSettingsPost(requestParameters: ProjectsApiCreateEuAiActProjectSettingsApiProjectsProjectIdEuAiActSettingsPostRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).createEuAiActProjectSettingsApiProjectsProjectIdEuAiActSettingsPost(requestParameters.projectId, requestParameters.eUAIActProjectSettingsCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new project.  Args:     new_project (CreateProject): The new project.     db (Session): Database session.     user (User): The user creating the project.  Returns:     Project: The created project.
     * @summary Create Project
     * @param {ProjectsApiCreateProjectApiProjectsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public createProjectApiProjectsPost(requestParameters: ProjectsApiCreateProjectApiProjectsPostRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).createProjectApiProjectsPost(requestParameters.createProject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new framework for the project with the given id.  Args:     framework_codes (List[str]): The framework codes to create.     response (Response): The response object.     user (User): The user creating the framework.     project (Project): The project to create the framework for.     db (Session): Database session.     cache_service (Cache): The cache service.  Returns:     Framework: The created framework.
     * @summary Create Project Framework
     * @param {ProjectsApiCreateProjectFrameworkApiProjectsProjectIdFrameworksPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public createProjectFrameworkApiProjectsProjectIdFrameworksPost(requestParameters: ProjectsApiCreateProjectFrameworkApiProjectsProjectIdFrameworksPostRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).createProjectFrameworkApiProjectsProjectIdFrameworksPost(requestParameters.projectId, requestParameters.requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new risk.  Args:     background_tasks:  The background tasks.     risk (schemas.CreateRisk): The risk to create.     response (Response): The response object.     db (Session, optional): The database session.     project (schemas.Project, optional): The project to create the risk for.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Risk: The created risk.
     * @summary Create Risk
     * @param {ProjectsApiCreateRiskApiProjectsProjectIdRisksPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public createRiskApiProjectsProjectIdRisksPost(requestParameters: ProjectsApiCreateRiskApiProjectsProjectIdRisksPostRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).createRiskApiProjectsProjectIdRisksPost(requestParameters.projectId, requestParameters.createRisk, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a monitoring datasource.  Args:     testing_log (TestingLogCreate): Monitoring datasource data.     project (Project): Project.     db (Session): Database session.  Returns:     TestingLog: Monitoring datasource.  Raises:     HTTPException: Project not found.
     * @summary Create Testing Log
     * @param {ProjectsApiCreateTestingLogApiV1ProjectsProjectIdTestingLogsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public createTestingLogApiV1ProjectsProjectIdTestingLogsPost(requestParameters: ProjectsApiCreateTestingLogApiV1ProjectsProjectIdTestingLogsPostRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).createTestingLogApiV1ProjectsProjectIdTestingLogsPost(requestParameters.projectId, requestParameters.testingLogCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Testing Metric
     * @param {ProjectsApiCreateTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public createTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost(requestParameters: ProjectsApiCreateTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPostRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).createTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost(requestParameters.testingSourceId, requestParameters.projectId, requestParameters.testingMetricCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Testing Test
     * @param {ProjectsApiCreateTestingTestApiV1ProjectsProjectIdTestingTestsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public createTestingTestApiV1ProjectsProjectIdTestingTestsPost(requestParameters: ProjectsApiCreateTestingTestApiV1ProjectsProjectIdTestingTestsPostRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).createTestingTestApiV1ProjectsProjectIdTestingTestsPost(requestParameters.projectId, requestParameters.createTestingTest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.
     * @summary Delete Project
     * @param {ProjectsApiDeleteProjectApiProjectsProjectIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public deleteProjectApiProjectsProjectIdDelete(requestParameters: ProjectsApiDeleteProjectApiProjectsProjectIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).deleteProjectApiProjectsProjectIdDelete(requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the project dashboard for the given project id.  Args:     project_id (UUID): The project ID.  Returns:     ProjectAiSystemLifecycleDashboard: The Lifecycle Dashboard.
     * @summary Get Ai System Life Cycle Dashboard
     * @param {ProjectsApiGetAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGet(requestParameters: ProjectsApiGetAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGet(requestParameters.projectId, requestParameters.dashboardContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Args:     object_id (UUID): The object ID.     object_type (enums.Concept): The object type. (enums.Concept)     response (Response): The response object.     project (schemas.Project): The project.     db (Session, optional): The database session.  Returns:     schemas.Comment: The updated control.
     * @summary Get Comments
     * @param {ProjectsApiGetCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGet(requestParameters: ProjectsApiGetCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGet(requestParameters.objectId, requestParameters.objectType, requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Control By Code
     * @param {ProjectsApiGetControlByCodeApiProjectsProjectIdControlsControlCodeGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getControlByCodeApiProjectsProjectIdControlsControlCodeGet(requestParameters: ProjectsApiGetControlByCodeApiProjectsProjectIdControlsControlCodeGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getControlByCodeApiProjectsProjectIdControlsControlCodeGet(requestParameters.controlCode, requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Controls By Testing Test
     * @param {ProjectsApiGetControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet(requestParameters: ProjectsApiGetControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet(requestParameters.projectId, requestParameters.testingTestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the project EU AI ActSettings.  Args:     settings (ProjectSettingsCreate): The project settings.     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     schemas.EUAIActProjectSettings: EU AI Act settings.
     * @summary Get Eu Ai Act Project Settings
     * @param {ProjectsApiGetEuAiActProjectSettingsApiProjectsProjectIdEuAiActSettingsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getEuAiActProjectSettingsApiProjectsProjectIdEuAiActSettingsGet(requestParameters: ProjectsApiGetEuAiActProjectSettingsApiProjectsProjectIdEuAiActSettingsGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getEuAiActProjectSettingsApiProjectsProjectIdEuAiActSettingsGet(requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the file types of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session. Returns:     List[schemas.FileType]: List of file types.
     * @summary Get Evidence File Types
     * @param {ProjectsApiGetEvidenceFileTypesApiProjectsProjectIdEvidenceFilesTypesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getEvidenceFileTypesApiProjectsProjectIdEvidenceFilesTypesGet(requestParameters: ProjectsApiGetEvidenceFileTypesApiProjectsProjectIdEvidenceFilesTypesGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getEvidenceFileTypesApiProjectsProjectIdEvidenceFilesTypesGet(requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the first 10 evidences (name, description and id) of the project with a name filter. This is used for a dropdown selection.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     name (str): Filter by evidence name.     params (Params): Pagination params.  Returns:     List[schemas.EvidencePreview]: List of evidences.
     * @summary Get Evidence List
     * @param {ProjectsApiGetEvidenceListApiProjectsProjectIdEvidenceListGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getEvidenceListApiProjectsProjectIdEvidenceListGet(requestParameters: ProjectsApiGetEvidenceListApiProjectsProjectIdEvidenceListGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getEvidenceListApiProjectsProjectIdEvidenceListGet(requestParameters.projectId, requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the evidences of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     name (str): Filter by evidence name.     params (Params): Pagination params.  Returns:     Page[schemas.Evidence]: Page of evidences.
     * @summary Get Evidences By Project Id
     * @param {ProjectsApiGetEvidencesByProjectIdApiProjectsProjectIdEvidenceGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getEvidencesByProjectIdApiProjectsProjectIdEvidenceGet(requestParameters: ProjectsApiGetEvidencesByProjectIdApiProjectsProjectIdEvidenceGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getEvidencesByProjectIdApiProjectsProjectIdEvidenceGet(requestParameters.projectId, requestParameters.name, requestParameters.sortBy, requestParameters.descending, requestParameters.associatedControls, requestParameters.associatedRisks, requestParameters.startDate, requestParameters.endDate, requestParameters.fileType, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the framework codes which has questionnaires.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     list[str]: framework codes.
     * @summary Get Frameworks Questionnaires
     * @param {ProjectsApiGetFrameworksQuestionnairesApiProjectsProjectIdFrameworksQuestionnairesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getFrameworksQuestionnairesApiProjectsProjectIdFrameworksQuestionnairesGet(requestParameters: ProjectsApiGetFrameworksQuestionnairesApiProjectsProjectIdFrameworksQuestionnairesGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getFrameworksQuestionnairesApiProjectsProjectIdFrameworksQuestionnairesGet(requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the logs for the given project and object id.  Args:     project_id (UUID): The project ID.     object_id (UUID): The object ID.  Returns:     List[Logs]: The logs.
     * @summary Get Logs
     * @param {ProjectsApiGetLogsApiProjectsProjectIdLogsObjectIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getLogsApiProjectsProjectIdLogsObjectIdGet(requestParameters: ProjectsApiGetLogsApiProjectsProjectIdLogsObjectIdGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getLogsApiProjectsProjectIdLogsObjectIdGet(requestParameters.objectId, requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the project with the given id. Args:     project_id (UUID): The project ID.  Returns:     Project: The project.
     * @summary Get Project
     * @param {ProjectsApiGetProjectApiProjectsProjectIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjectApiProjectsProjectIdGet(requestParameters: ProjectsApiGetProjectApiProjectsProjectIdGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjectApiProjectsProjectIdGet(requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the asset_cards of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     Page[schemas.AssetCardPage]: List of asset_cards.
     * @summary Get Project Asset Cards
     * @param {ProjectsApiGetProjectAssetCardsApiProjectsProjectIdAssetCardsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjectAssetCardsApiProjectsProjectIdAssetCardsGet(requestParameters: ProjectsApiGetProjectAssetCardsApiProjectsProjectIdAssetCardsGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjectAssetCardsApiProjectsProjectIdAssetCardsGet(requestParameters.projectId, requestParameters.name, requestParameters.status, requestParameters.isAssignedToMe, requestParameters.assetCardType, requestParameters.assetCardNature, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the controls of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     Page[schemas.ControlWithoutComponents]: List of controls.
     * @summary Get Project Controls
     * @param {ProjectsApiGetProjectControlsApiProjectsProjectIdControlsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjectControlsApiProjectsProjectIdControlsGet(requestParameters: ProjectsApiGetProjectControlsApiProjectsProjectIdControlsGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjectControlsApiProjectsProjectIdControlsGet(requestParameters.projectId, requestParameters.tags, requestParameters.name, requestParameters.framework, requestParameters.requirement, requestParameters.status, requestParameters.isAssignedToMe, requestParameters.isAssignedToMeForReview, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Project Controls
     * @param {ProjectsApiGetProjectControlsApiV1ProjectsProjectIdControlsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjectControlsApiV1ProjectsProjectIdControlsGet(requestParameters: ProjectsApiGetProjectControlsApiV1ProjectsProjectIdControlsGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjectControlsApiV1ProjectsProjectIdControlsGet(requestParameters.projectId, requestParameters.status, requestParameters.name, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the controls of the project with the given id which have at least one evidence.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     List[schemas.Risk]: List of risks.
     * @summary Get Project Controls With Evidences
     * @param {ProjectsApiGetProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGet(requestParameters: ProjectsApiGetProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGet(requestParameters.projectId, requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the project dashboard for the given project id.  Args:     project_id (UUID): The project ID.  Returns:     Project: The project dashboard.
     * @summary Get Project Dashboard
     * @param {ProjectsApiGetProjectDashboardApiProjectsProjectIdDashboardGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjectDashboardApiProjectsProjectIdDashboardGet(requestParameters: ProjectsApiGetProjectDashboardApiProjectsProjectIdDashboardGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjectDashboardApiProjectsProjectIdDashboardGet(requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the frameworks of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     name (str): Filter by framework name.     params (Params): Pagination params.  Returns:     Page[schemas.FrameworkAggregator]: List of frameworks.
     * @summary Get Project Frameworks
     * @param {ProjectsApiGetProjectFrameworksApiProjectsProjectIdFrameworksGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjectFrameworksApiProjectsProjectIdFrameworksGet(requestParameters: ProjectsApiGetProjectFrameworksApiProjectsProjectIdFrameworksGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjectFrameworksApiProjectsProjectIdFrameworksGet(requestParameters.projectId, requestParameters.name, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the project pdf for the given project id.  Args:     project_id (UUID): The project ID.  Returns:     FileResponse: The project pdf.
     * @summary Get Project Pdf
     * @param {ProjectsApiGetProjectPdfApiProjectsProjectIdExportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjectPdfApiProjectsProjectIdExportGet(requestParameters: ProjectsApiGetProjectPdfApiProjectsProjectIdExportGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjectPdfApiProjectsProjectIdExportGet(requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the requirements of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     name (str): Filter by requirement name.     framework (str): Filter by framework code.     status (str): Filter by requirement status.     params (Params): Pagination params.  Returns:     Page[schemas.Requirement]: Paginated List of requirements.
     * @summary Get Project Requirements
     * @param {ProjectsApiGetProjectRequirementsApiProjectsProjectIdRequirementsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjectRequirementsApiProjectsProjectIdRequirementsGet(requestParameters: ProjectsApiGetProjectRequirementsApiProjectsProjectIdRequirementsGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjectRequirementsApiProjectsProjectIdRequirementsGet(requestParameters.projectId, requestParameters.name, requestParameters.framework, requestParameters.status, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the risks of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     Page[schemas.RiskWithoutComponentsAggregator]: List of risks.
     * @summary Get Project Risks
     * @param {ProjectsApiGetProjectRisksApiProjectsProjectIdRisksGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjectRisksApiProjectsProjectIdRisksGet(requestParameters: ProjectsApiGetProjectRisksApiProjectsProjectIdRisksGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjectRisksApiProjectsProjectIdRisksGet(requestParameters.projectId, requestParameters.name, requestParameters.status, requestParameters.isAssignedToMe, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the risks of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     Page[schemas.RiskWithoutComponentsAggregator]: List of risks.
     * @summary Get Project Risks List
     * @param {ProjectsApiGetProjectRisksListApiProjectsProjectIdRisksListGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjectRisksListApiProjectsProjectIdRisksListGet(requestParameters: ProjectsApiGetProjectRisksListApiProjectsProjectIdRisksListGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjectRisksListApiProjectsProjectIdRisksListGet(requestParameters.projectId, requestParameters.name, requestParameters.status, requestParameters.isAssignedToMe, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the risks of the project with the given id which have at least one evidence.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     List[schemas.Risk]: List of risks.
     * @summary Get Project Risks With Evidences
     * @param {ProjectsApiGetProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGet(requestParameters: ProjectsApiGetProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGet(requestParameters.projectId, requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Reviews For Concept Type
     * @param {ProjectsApiGetReviewsForConceptTypeApiProjectsProjectIdReviewsConceptGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getReviewsForConceptTypeApiProjectsProjectIdReviewsConceptGet(requestParameters: ProjectsApiGetReviewsForConceptTypeApiProjectsProjectIdReviewsConceptGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getReviewsForConceptTypeApiProjectsProjectIdReviewsConceptGet(requestParameters.concept, requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Reviews For Object Id
     * @param {ProjectsApiGetReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGet(requestParameters: ProjectsApiGetReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGet(requestParameters.objectId, requestParameters.concept, requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Testing Metric
     * @param {ProjectsApiGetTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet(requestParameters: ProjectsApiGetTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet(requestParameters.testingSourceId, requestParameters.testingMetricId, requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Testing Source
     * @param {ProjectsApiGetTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet(requestParameters: ProjectsApiGetTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet(requestParameters.testingSourceId, requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Testing Source Metrics
     * @param {ProjectsApiGetTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet(requestParameters: ProjectsApiGetTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet(requestParameters.testingSourceId, requestParameters.projectId, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Testing Sources
     * @param {ProjectsApiGetTestingSourcesApiV1ProjectsProjectIdTestingSourcesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet(requestParameters: ProjectsApiGetTestingSourcesApiV1ProjectsProjectIdTestingSourcesGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet(requestParameters.projectId, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Testing Test And Latest Result
     * @param {ProjectsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet(requestParameters: ProjectsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet(requestParameters.projectId, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, requestParameters.name, requestParameters.resultStatus, requestParameters.status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Testing Test
     * @param {ProjectsApiGetTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet(requestParameters: ProjectsApiGetTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet(requestParameters.projectId, requestParameters.testingTestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Testing Test Result
     * @param {ProjectsApiGetTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet(requestParameters: ProjectsApiGetTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet(requestParameters.testingTestResultId, requestParameters.projectId, requestParameters.testingTestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Testing Test Results
     * @param {ProjectsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet(requestParameters: ProjectsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet(requestParameters.projectId, requestParameters.testingTestId, requestParameters.resultStatus, requestParameters.page, requestParameters.size, requestParameters.sortBy, requestParameters.descending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Testing Tests
     * @param {ProjectsApiGetTestingTestsApiV1ProjectsProjectIdTestingTestsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getTestingTestsApiV1ProjectsProjectIdTestingTestsGet(requestParameters: ProjectsApiGetTestingTestsApiV1ProjectsProjectIdTestingTestsGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getTestingTestsApiV1ProjectsProjectIdTestingTestsGet(requestParameters.projectId, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the users of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     params (Params): Pagination params.     roles (List[enums.Roles]): Filter by role.     exclude_me (bool): Include current user.     sort_by (str): Sort by a field.     descending (bool): Sort order. If true, descending, otherwise ascending.  Returns:     Page[schemas.OrganizationUser]: List of users.
     * @summary Get User Of Project
     * @param {ProjectsApiGetUserOfProjectApiProjectsProjectIdUsersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getUserOfProjectApiProjectsProjectIdUsersGet(requestParameters: ProjectsApiGetUserOfProjectApiProjectsProjectIdUsersGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getUserOfProjectApiProjectsProjectIdUsersGet(requestParameters.projectId, requestParameters.roles, requestParameters.excludeMe, requestParameters.sortBy, requestParameters.descending, requestParameters.name, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove Control From Testing Test
     * @param {ProjectsApiRemoveControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public removeControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete(requestParameters: ProjectsApiRemoveControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).removeControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete(requestParameters.projectId, requestParameters.testingTestId, requestParameters.controlId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Request access to the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     user (User): The user requesting access to the project.
     * @summary Request Access
     * @param {ProjectsApiRequestAccessApiProjectsProjectIdRequestAccessPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public requestAccessApiProjectsProjectIdRequestAccessPost(requestParameters: ProjectsApiRequestAccessApiProjectsProjectIdRequestAccessPostRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).requestAccessApiProjectsProjectIdRequestAccessPost(requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Request reviews for a concept object.  Args:     review_request (schemas.ReviewRequest): The review request.     concept (enums.Concept): The concept of the object.     concept_object (schemas.ControlAggregator | schemas.Risk | schemas.AssetCard, optional): The object to request reviews for. # noqa     db (Session, optional): The database session.     project (schemas.Project, optional): The project.     current_user (schemas.OrganizationUser, optional): The current user.     force_update_status (bool, optional): Project owners can force update the         status of the object.  Returns:     List[schemas.Review]: The created reviews.
     * @summary Request Reviews
     * @param {ProjectsApiRequestReviewsApiProjectsProjectIdReviewsConceptObjectIdPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public requestReviewsApiProjectsProjectIdReviewsConceptObjectIdPost(requestParameters: ProjectsApiRequestReviewsApiProjectsProjectIdReviewsConceptObjectIdPostRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).requestReviewsApiProjectsProjectIdReviewsConceptObjectIdPost(requestParameters.concept, requestParameters.objectId, requestParameters.projectId, requestParameters.reviewRequest, requestParameters.forceUpdateStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Run Testing Test
     * @param {ProjectsApiRunTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public runTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost(requestParameters: ProjectsApiRunTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPostRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).runTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost(requestParameters.projectId, requestParameters.testingTestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Take a decision on a review for a concept object.  Args:     project_id (uuid.UUID): The project ID.     concept (enums.Concept): The concept of the object.     review_decision (schemas.ReviewDecision): The review decision.     concept_object (schemas.ControlAggregator | schemas.Risk | schemas.AssetCard, optional): The object. # noqa     db (Session, optional): The database session.     current_user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Review: The updated review.
     * @summary Take Review Decision
     * @param {ProjectsApiTakeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public takeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatch(requestParameters: ProjectsApiTakeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatchRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).takeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatch(requestParameters.projectId, requestParameters.concept, requestParameters.objectId, requestParameters.reviewDecision, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the project with the given id.  Args:     project_id (UUID): The project\'s id.     project_update (UpdateProject): The update to the project.     db (Session): Database session.  Returns:     schemas.Project: The updated project.
     * @summary Update Project
     * @param {ProjectsApiUpdateProjectApiProjectsProjectIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public updateProjectApiProjectsProjectIdPatch(requestParameters: ProjectsApiUpdateProjectApiProjectsProjectIdPatchRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).updateProjectApiProjectsProjectIdPatch(requestParameters.projectId, requestParameters.updateProject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the reviewers for a concept object.  Args:     review_request (schemas.UpdateReviewRequest): The update review request.     concept (enums.Concept): The concept of the object.     concept_object (schemas.ControlAggregator | schemas.Risk | schemas.AssetCard, optional): The object. # noqa     db (Session, optional): The database session.     project (schemas.Project, optional): The project.     current_user (schemas.OrganizationUser, optional): The current user.  Returns:     List[schemas.Review]: The updated reviews.
     * @summary Update Reviewers
     * @param {ProjectsApiUpdateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public updateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatch(requestParameters: ProjectsApiUpdateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatchRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).updateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatch(requestParameters.concept, requestParameters.objectId, requestParameters.projectId, requestParameters.updateReviewRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Testing Test
     * @param {ProjectsApiUpdateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public updateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch(requestParameters: ProjectsApiUpdateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatchRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).updateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch(requestParameters.projectId, requestParameters.testingTestId, requestParameters.updateTestingTest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Uploads the evidence file and creates an entry in the evidence database.  Args:     db (Session): Database session.     project_id (UUID): The project\'s id.     user (User): The user creating the evidence.     file (UploadFile): The file to upload.     component (Component): The component the evidence belongs to.  Returns:     schemas.Evidence: The created evidence.
     * @summary Upload And Create Evidence
     * @param {ProjectsApiUploadAndCreateEvidenceApiProjectsProjectIdEvidencePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public uploadAndCreateEvidenceApiProjectsProjectIdEvidencePost(requestParameters: ProjectsApiUploadAndCreateEvidenceApiProjectsProjectIdEvidencePostRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).uploadAndCreateEvidenceApiProjectsProjectIdEvidencePost(requestParameters.projectId, requestParameters.file, requestParameters.name, requestParameters.description, requestParameters.componentId, options).then((request) => request(this.axios, this.basePath));
    }
}

