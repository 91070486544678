<script setup lang="ts">
import { lockOutlined } from '@/assets/icons/materialSymbols/MaterialSymbols.ts';

import { hideContactSalesModal } from '@/composables/Flags.ts';

function closeDialog() {
  hideContactSalesModal();
}
</script>

<template>
  <div class="wrapp column">
    <div class="row col-12 justify-center">
      <div class="lock__container row items-center justify-center">
        <q-icon :name="lockOutlined" color="blue-grey-5" size="24px" />
      </div>
    </div>
    <div class="row col-12 justify-center text-center">
      <h3>You have reached the available limit.</h3>
    </div>
    <div class="row col-12 justify-center text-center q-mb-sm">
      <p>Contact sales to upgrade your plan.</p>
    </div>
    <div class="row col-12 justify-center">
      <q-btn class="btn-cancel" label="Cancel" unelevated @click="closeDialog" />
      <q-btn
        class="btn-request"
        label="Contact Sales"
        unelevated
        :href="`mailto:sales@modulos.ai`"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.wrapp {
  padding: 10px 0;

  .lock__container {
    width: 64px;
    height: 64px;
    background: $secondary-50;
    border-radius: 50%;
  }

  h3 {
    @include heading-03(700, $secondary-700);
  }
  p {
    @include paragraph-02(400, #8c8c8c);
  }
  .btn-cancel {
    padding: 10px 0;
    min-width: 200px;
    color: $secondary-500;
    background: transparent !important;
    border: 1px solid $secondary-500;
    text-transform: none;
    margin-right: 10px;
  }
  .btn-request {
    padding: 10px 0;
    min-width: 200px;
    color: $white;
    background: $secondary-500 !important;
    text-transform: none;
  }
}
</style>
